import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import { CheckBox, CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import { Box } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MouseEvent, PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';

type NumberOrString = number | string;
export type MenuPickerOption<OptionType = NumberOrString> = {
  label: string;
  value: OptionType;
  background?: null | string;
  icon?: null | string;
  color?: string | null;
};

export type MenuMultiplePickerProps<OptionType = NumberOrString> = {
  value: (MenuPickerOption<OptionType> | OptionType)[];
  options: MenuPickerOption<OptionType>[];
  onChange?: (value: MenuPickerOption<OptionType>[]) => void;
  defaultBackground?: string | null;
  defaultColor?: string | null;
};

type IndexedOptions<OptionType = NumberOrString> = {
  [key in NumberOrString]: MenuPickerOption<OptionType>;
};

export default function MenuMultiplePicker<OptionType extends NumberOrString = NumberOrString>(
  props: PropsWithChildren<MenuMultiplePickerProps<OptionType>>,
) {
  const { value, children, options, onChange, defaultBackground, defaultColor } = props;
  const indexedOptions: IndexedOptions<OptionType> = useMemo<IndexedOptions<OptionType>>(() => {
    return options.reduce<IndexedOptions<OptionType>>((acc, option) => {
      acc[option.value] = option;
      return acc;
    }, {});
  }, [options]);
  const [normalizedValue, setNormalizedValue] = useState<MenuPickerOption<OptionType>[]>(
    value
      .filter(Boolean)
      .map<MenuPickerOption<OptionType>>(val => {
        if (typeof val === 'object') {
          return val;
        }

        return indexedOptions[val];
      })
      .filter(val => val !== undefined),
  );
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    const computedNormalizedValue = value
      .map<MenuPickerOption<OptionType>>(val => {
        if (typeof val === 'object') {
          return val;
        }

        return indexedOptions[val];
      })
      .filter(val => val !== undefined);

    if (JSON.stringify(computedNormalizedValue) !== JSON.stringify(normalizedValue)) {
      setNormalizedValue(computedNormalizedValue);
    }
  }, [value]);
  useEffect(() => {
    onChange?.(normalizedValue);
  }, [normalizedValue]);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectValue = (option: MenuPickerOption<OptionType>) => {
    if (null === option.value) {
      setNormalizedValue([]);
      handleClose();
      return;
    }
    const isSelected = normalizedValue.find(val => val.value === option.value) !== undefined;
    const newValue = isSelected ? normalizedValue.filter(val => val.value !== option.value) : [...Object.values(normalizedValue), option];

    setNormalizedValue(newValue);
  };

  let selectedOption: null | MenuPickerOption<OptionType> = null;

  if (normalizedValue.length === 1) {
    selectedOption = normalizedValue[0];
  } else if (normalizedValue.length > 1) {
    selectedOption = {
      color: '#303030',
      label: `${normalizedValue.length} Wybrane`,
      value: 'ND' as OptionType,
    };
  }

  const clearValue = () => {
    setNormalizedValue([]);
    handleClose();
  };

  return (
    <div ref={wrapperRef} className="w-100">
      {!selectedOption ? (
        <div onClick={handleClick}>{children}</div>
      ) : (
        <ColoredIconBox
          value={selectedOption.label}
          background={selectedOption.background ?? defaultBackground}
          icon={selectedOption.icon}
          color={selectedOption.color ?? defaultColor}
          onClick={handleClick}
          sx={{ cursor: 'pointer' }}
        />
      )}

      <Menu anchorEl={wrapperRef.current} open={open} onClose={handleClose}>
        {normalizedValue.length > 0 && (
          <ColoredIconBox
            value={'Wyczyść'}
            background={defaultBackground}
            icon={'close'}
            color={defaultColor}
            onClick={clearValue}
            sx={{ cursor: 'pointer' }}
          />
        )}
        {options.map((option, index) => (
          <MenuItem
            key={index}
            onClick={() => handleSelectValue(option)}
            selected={normalizedValue.find(val => val.value === option.value) !== undefined}
          >
            <Box display="flex" justifyItems="center" alignItems="center" className="w-100">
              <div style={{ paddingRight: 20 }}>
                {normalizedValue.find(val => val.value === option.value) ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
              </div>
              <div className=" w-100">
                <ColoredIconBox
                  value={option.label}
                  background={option.background ?? defaultBackground}
                  color={option.color ?? defaultColor}
                  icon={option.icon}
                  reverseColors={normalizedValue.find(val => val.value === option.value) !== undefined}
                  sx={{
                    cursor: 'pointer',
                    border: 1,
                    borderColor: option.background ?? defaultBackground,
                    borderStyle: 'groove',
                  }}
                />
              </div>
            </Box>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
