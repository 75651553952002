import { ClientOrder } from '@Ecommerce/ClientOrder';
import { InsertLinkSharp } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import { FC, MouseEvent, useState } from 'react';

type OpenOrderLinksProps = {
  order: ClientOrder;
};

const OpenOrderLinks: FC<OpenOrderLinksProps> = ({ order }) => {
  const [dropdownOpen, setDropdownOpen] = useState<null | HTMLElement>(null);
  if (order.links.length === 0) {
    return null;
  }
  const handleClick = (event: MouseEvent<HTMLElement>) => setDropdownOpen(event.currentTarget);

  const handleClose = () => setDropdownOpen(null);

  return (
    <>
      <MenuItem onClick={handleClick}>
        <InsertLinkSharp /> Zewnętrzne systemy
      </MenuItem>
      <Menu
        anchorEl={dropdownOpen}
        open={Boolean(dropdownOpen)}
        onClose={handleClose}
        keepMounted
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {order.links.map((link, index) => (
          <MenuItem key={index} component="a" href={link.url} target="_blank" onClick={handleClose}>
            {link.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default OpenOrderLinks;
