import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { Col, Container } from '@Components/Theme/Grid';
import { Paper } from '@mui/material';
import { FC } from 'react';

const InvoiceCommentsGroup: FC<InvoiceGroupProps> = props => {
  return (
    <Paper>
      <Container>
        <Col xs={12}>
          <InvoiceField {...props} fieldKey={'description'} />
        </Col>
        <Col xs={12} sx={{ marginBottom: 2 }}>
          <InvoiceField {...props} fieldKey={'ownDescription'} />
        </Col>
      </Container>
    </Paper>
  );
};

export default InvoiceCommentsGroup;
