import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { IndexedField } from '@Components/CustomFields/CustomField';
import { Col, Container } from '@Components/Theme/Grid';
import { Contractor } from '@Contractor/Contractor';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { get } from 'helpers/Axios';
import { FC } from 'react';

const InvoiceContractorFieldsGroup: FC<InvoiceGroupProps & { fieldPrefix: 'seller' | 'buyer' | 'recipient' }> = props => {
  const contractorWasChanged = (field: IndexedField, newValue: null | Contractor, prevValue: null | Contractor) => {
    if (newValue) {
      get(newValue['@id']).then(contractorFullData => {
        props.onUpdate(contractorFullData?.name, props.fields[`${props.fieldPrefix}Raw.name`].id);
        props.onUpdate(contractorFullData?.tin.prefix, props.fields[`${props.fieldPrefix}Raw.taxNumberPrefix`].id);
        props.onUpdate(contractorFullData?.tin.number, props.fields[`${props.fieldPrefix}Raw.taxNumber`].id);
        props.onUpdate(contractorFullData?.address.post, props.fields[`${props.fieldPrefix}Raw.post`].id);
        props.onUpdate(contractorFullData?.address.city, props.fields[`${props.fieldPrefix}Raw.city`].id);
        props.onUpdate(contractorFullData?.address.province, props.fields[`${props.fieldPrefix}Raw.province`].id);
        props.onUpdate(contractorFullData?.address.district, props.fields[`${props.fieldPrefix}Raw.district`].id);
        props.onUpdate(contractorFullData?.address.street, props.fields[`${props.fieldPrefix}Raw.street`].id);
        props.onUpdate(contractorFullData?.address.house, props.fields[`${props.fieldPrefix}Raw.house`].id);
        props.onUpdate(contractorFullData?.address.flat, props.fields[`${props.fieldPrefix}Raw.flat`].id);
        props.onUpdate(contractorFullData?.contact.comment, props.fields[`${props.fieldPrefix}Raw.additionalInformation`].id);
        props.onUpdate(contractorFullData?.contact.firstMail, props.fields[`${props.fieldPrefix}Raw.email`].id);
        props.onUpdate(contractorFullData?.contact.phone, props.fields[`${props.fieldPrefix}Raw.phone`].id);

        if (props.fieldPrefix === 'seller') {
          const defaultDescriptionField =
            Object.values<IndexedField>(contractorFullData['@form']).find(
              el => el.propertyPath === 'accountancy.defaultDescriptionOnInvoice',
            )?.id ?? '';

          props.onUpdate(
            contractorFullData['@formValues']?.[defaultDescriptionField]?.value?.[props.values[props.fields.lang.id]] ?? null,
            props.fields.description.id,
          );
        }
      });
    } else {
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.name`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.taxNumberPrefix`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.taxNumber`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.post`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.city`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.province`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.district`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.street`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.house`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.flat`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.additionalInformation`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.email`].id);
      props.onUpdate(null, props.fields[`${props.fieldPrefix}Raw.phone`].id);
    }

    if (props.fieldPrefix === 'buyer' && props.values[props.fields.positions.id].length === 0 && newValue?.valueAddedTax) {
      props.onUpdate([{ quantity: 1, tax: newValue.valueAddedTax }], props.fields.positions.id);
    }
  };

  const isPossibleOss =
    props.fieldPrefix === 'buyer' &&
    `${props.values[props.fields['sellerRaw.country'].id] ?? ''}`.length > 0 &&
    `${props.values[props.fields['buyerRaw.country'].id] ?? ''}`.length > 0 &&
    props.values[props.fields['sellerRaw.country'].id] !== props.values[props.fields['buyerRaw.country'].id];

  return (
    <Paper>
      <Container style={{ padding: '0 12px' }}>
        <Col xs={12}>
          <Container>
            <div style={{ padding: '0 12px' }}>
              <Accordion sx={{ boxShadow: '0', borderRadius: 0 }}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                  <Container>
                    <Col xs={isPossibleOss ? 8 : 12}>
                      {' '}
                      <InvoiceField<Contractor>
                        {...props}
                        size={12}
                        fieldKey={props.fieldPrefix}
                        valueWasChanged={contractorWasChanged}
                        fieldOverride={{ name: props.fields[props.fieldPrefix].name }}
                      />
                    </Col>
                    {isPossibleOss && (
                      <Col xs={4}>
                        <InvoiceField {...props} fieldKey={'oss'} />
                      </Col>
                    )}
                  </Container>
                </AccordionSummary>
                <AccordionDetails>
                  <Container style={{ padding: '0 12px' }}>
                    <InvoiceField {...props} size={12} fieldKey={`${props.fieldPrefix}Raw.name`} fieldOverride={{ name: 'Nazwa' }} />
                    <InvoiceField
                      {...props}
                      size={3}
                      fieldKey={`${props.fieldPrefix}Raw.taxNumberPrefix`}
                      fieldOverride={{ name: 'Prefix NIP' }}
                    />
                    <InvoiceField
                      {...props}
                      size={9}
                      fieldKey={`${props.fieldPrefix}Raw.taxNumber`}
                      fieldOverride={{ name: 'Numer NIP' }}
                    />
                    <InvoiceField
                      {...props}
                      size={4}
                      fieldKey={`${props.fieldPrefix}Raw.bankName`}
                      fieldOverride={{ name: 'Nazwa banku' }}
                    />
                    <InvoiceField
                      {...props}
                      size={8}
                      fieldKey={`${props.fieldPrefix}Raw.bankAccount`}
                      fieldOverride={{ name: 'Numer konta bankowego' }}
                    />
                    <InvoiceField {...props} size={3} fieldKey={`${props.fieldPrefix}Raw.post`} fieldOverride={{ name: 'Kod pocztowy' }} />
                    <InvoiceField {...props} size={9} fieldKey={`${props.fieldPrefix}Raw.city`} fieldOverride={{ name: 'Miasto' }} />
                    <InvoiceField {...props} size={4} fieldKey={`${props.fieldPrefix}Raw.country`} fieldOverride={{ name: 'Kraj' }} />
                    <InvoiceField
                      {...props}
                      size={4}
                      fieldKey={`${props.fieldPrefix}Raw.province`}
                      fieldOverride={{ name: 'Województwo' }}
                    />
                    <InvoiceField {...props} size={4} fieldKey={`${props.fieldPrefix}Raw.district`} fieldOverride={{ name: 'Gmina' }} />
                    <InvoiceField {...props} size={6} fieldKey={`${props.fieldPrefix}Raw.street`} fieldOverride={{ name: 'Ulica' }} />
                    <InvoiceField {...props} size={3} fieldKey={`${props.fieldPrefix}Raw.house`} fieldOverride={{ name: 'Budynek' }} />
                    <InvoiceField {...props} size={3} fieldKey={`${props.fieldPrefix}Raw.flat`} fieldOverride={{ name: 'Lokal' }} />
                    <InvoiceField
                      {...props}
                      size={12}
                      fieldKey={`${props.fieldPrefix}Raw.additionalInformation`}
                      fieldOverride={{ name: 'Dodatkowe informacje' }}
                    />
                  </Container>
                </AccordionDetails>
              </Accordion>
            </div>
          </Container>
        </Col>
      </Container>
    </Paper>
  );
};

export default InvoiceContractorFieldsGroup;
