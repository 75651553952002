import { createEditableField } from '../../helpers/ModuleUtils';
import StatusView from '../Core/Components/StatusView';
import DownloadTab from '../CustomFields/CoreComponents/DownloadTab';
import ReservationModal from '../Warehouse/Components/Warehouse/ReservationModal';
import Place from '../Warehouse/Place';
import SalePicker from './Components/Product/SalePicker';
import SpecialProfit from './Components/Product/SpecialProfit';
import Suppliers from './Components/Product/Suppliers';
import Ebay from './Product/Button/Ebay';
import ProductPlace from './Product/List/ProductPlace';
import ProductStoreActive from './Product/List/ProductStoreActive';
import ProductGroup from './ProductGroup';
import ProductSpecies from './ProductSpecies';
import ProductType from './ProductType';
import TechnologyModule from './Technology';
import { Technology } from './Types/Technology';
import CheckboxColumn from '@Components/DataGrid/ColumnView/CheckboxColumn';
import FilterModuleProperty from '@Components/DataGrid/Filter/FilterModuleProperty';
import { Button, Tooltip } from '@mui/material';
import CTMModule, { CTMApiPathParametersPutItem, CTMListColumn, CTMPath, CTMRecord, CTMRecordValue } from 'Modules/Core/Types/CTMModule';
import { TabConfiguration as IngredientViewTabConfiguration } from 'Modules/Manufacture/Components/Product/Bom/IngredientView';
import ProductPrices from 'Modules/Manufacture/Components/Product/ProductPrices';
import LabelPicker from 'Modules/Manufacture/Label/Component/LabelPicker';
import ProductName from 'Modules/Manufacture/Product/List/ProductName';
import DocumentTab from 'Modules/Warehouse/Components/Document/DocumentTab';
import classnames from 'classnames';
import CopyItemButton from 'components/DataGrid/Button/CopyItemButton';
import { SelectColumnFilter, SelectModuleRecordColumnFilter } from 'components/DataGrid/Filter';
import SecuredView from 'components/Theme/Common/SecuredView';
import EanField from 'pages/Manufacture/Product/Components/EanField';
import EditWrapper from 'pages/Manufacture/Product/Components/EditWrapper';
import LockButton from 'pages/Manufacture/Product/Components/LockButton';
import LockButtonForm from 'pages/Manufacture/Product/Components/LockButtonForm';
import ProductTechnologies from 'pages/Manufacture/Product/Components/ProductTechnologies';
import ChildGrid from 'pages/Manufacture/Product/ListComponents/ChildGrid';
import ProductGroupView from 'pages/Manufacture/Product/ListComponents/ProductGroup';
import ProductQuantity from 'pages/Manufacture/Product/ListComponents/ProductQuantity';

export type Product = CTMRecord & {
  id: string;
  name?: string;
  technology?: null | CTMRecordValue<Technology>;
  requiredProducts: RequiredProduct[];
  specialProfit?: null | number;
  weightTotal?: null | number;
  packageLength?: null | number;
  packageThickness?: null | number;
  packageWidth?: null | number;
  requiresAttentionWhenPacking?: boolean;
};

export type RequiredProduct = CTMRecord & {
  id?: string;
  product: null | Product;
  productFilter?: any;
  quantity: number;
  unit: any;
  used?: number;
};

export const LabelerIdColumn = ({ row }) => {
  return (
    <div>
      <div className="d-flex align-center">
        {(row.original?.requiredProductsCount ?? row.original?.product?.requiredProductsCount ?? 0) > 0 && (
          <i
            style={{ color: `${row.original?.type?.color || '#484f56'}` }}
            className={classnames('mdi font-size-16', {
              [`mdi-${row.original.type?.icon ?? 'folder'}`]: true,
            })}
          />
        )}
        {(row.original?.requiredProductsCount ?? row.original?.requiredProductsCount ?? 0) === 0 &&
          (row.original.type ? (
            <i
              style={{ color: `${row.original?.type?.color || '#484f56'}` }}
              className={`mdi mdi-${row.original.type?.icon ?? 'text-box-outline'} font-size-16`}
            />
          ) : (
            <span style={{ padding: '0 8px' }} />
          ))}
        <span className="d-flex align-items-center" style={{ marginLeft: 4 }}>
          {row.original.labelerId}
        </span>
      </div>
    </div>
  );
};

const buttonStyle = { borderRadius: '5px', padding: '2px', minWidth: '30px', color: '#fff', width: '100%' };

const columns: CTMListColumn<Product>[] = [
  {
    id: 'group.id',
    filterable: true,
    sortable: true,
    Header: 'Kategoria',
    accessor: 'group.name',
    Cell: ({ row }) => <ProductGroupView name={row.original.group?.name} path={row.original.group?.relationFieldLabel} />,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductGroup,
    minWidth: 220,
    maxWidth: 220,
  },
  {
    id: 'labelerId',
    filterable: true,
    sortable: true,
    Header: 'ID',
    Cell: ({ row }) => <LabelerIdColumn row={row} />,
    accessor: () => {
      return null;
    },
    width: 100,
  },
  {
    id: 'sale',
    filterable: true,
    sortable: true,
    Header: 'Sprzedaż',
    accessor: ({ sale }) => <StatusView moduleName={'manufacture-products'} status={sale} propertyName="sale" iconOnly={true} />,
    Filter: FilterModuleProperty,
    filterModuleName: 'manufacture-products',
    filterPropertyName: 'sale',
    filterMultiple: true,
  },
  {
    id: 'name',
    filterable: true,
    sortable: true,
    autofocus: true,
    Header: 'Nazwa',
    accessor: ({ name, image }) => <ProductName name={name} image={image} />,
    minWidth: 280,
    maxWidth: 280,
  },
  {
    id: 'storeActive',
    Header: 'Dostępn. na sklepie',
    accessor: ({ storeActive }) => <ProductStoreActive available={storeActive} />,
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Dostepne' },
      { value: '0', label: 'Niedostępne' },
    ],
    minWidth: 150,
    maxWidth: 150,
  },
  {
    id: 'symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    accessor: 'symbol',
    Cell: ({ row }) => <span className="text-nowrap">{row.original.symbol}</span>,
    minWidth: 260,
    maxWidth: 260,
  },
  {
    id: 'ean',
    filterable: true,
    sortable: true,
    Header: 'EAN',
    accessor: 'ean',
    minWidth: 130,
    maxWidth: 130,
  },
  {
    id: 'species.id',
    filterable: true,
    sortable: true,
    Header: 'Gatunek',
    accessor: row => <>{row?.species?.name}</>,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductSpecies,
    Cell: ({ row }) => <span className="text-nowrap">{row.original.species?.name}</span>,
    minWidth: 120,
    maxWidth: 120,
  },
  {
    id: 'productQuantity.quantity',
    Header: 'S',
    accessor: ({ unit, productQuantity, warehouseQuantities }) => (
      <ProductQuantity
        unit={unit}
        quantity={productQuantity?.quantity ?? 0}
        details={warehouseQuantities.map(el => ({
          name: el.warehouse?.name ?? 'Brak przypisanego magazynu',
          quantity: el.quantity ?? 0,
        }))}
      />
    ),
    sortable: true,
  },
  {
    id: 'quantityReserved',
    Header: 'R',
    accessor: ({ quantityReserved, id }) => (
      <ReservationModal productId={id} type={'RM'}>
        <Button variant={'contained'} color={'warning'} style={buttonStyle}>
          {quantityReserved}
        </Button>
      </ReservationModal>
    ),
    sortable: true,
  },
  {
    id: 'productQuantity.quantityAvailed',
    Header: 'D',
    accessor: ({ quantityAvailable }) => (
      <Tooltip title={'Ilość dostępnych'}>
        <Button variant={'contained'} color={'success'} style={buttonStyle}>
          {quantityAvailable}
        </Button>
      </Tooltip>
    ),
    sortable: true,
  },
  {
    id: 'quantityOrdered',
    Header: () => <span>Z</span>,
    accessor: ({ quantityOrdered }) => (
      <Tooltip title={'Ilość zamówionych'}>
        <Button variant={'contained'} color={'primary'} style={buttonStyle}>
          {quantityOrdered}
        </Button>
      </Tooltip>
    ),
    sortable: false,
  },
  {
    id: 'quantityOrderedReserved',
    Header: () => <span>RZ</span>,
    accessor: ({ quantityOrderedReserved, id }) => (
      <ReservationModal productId={id} type={'RZ'}>
        <Button variant={'contained'} color={'error'} style={buttonStyle}>
          {quantityOrderedReserved}
        </Button>
      </ReservationModal>
    ),
    sortable: true,
  },
  {
    id: 'quantityOrderedAvailable',
    Header: () => <span>DZ</span>,
    accessor: ({ quantityOrderedAvailable }) => (
      <Tooltip title={'Ilość dostępnych/zamówionych'}>
        <Button variant={'contained'} color={'secondary'} style={buttonStyle}>
          {quantityOrderedAvailable}
        </Button>
      </Tooltip>
    ),
    sortable: false,
  },
  {
    id: 'price',
    Header: () => (
      <span>
        Cena
        <br />
        kat.
      </span>
    ),
    accessor: ({ price }) => {
      return price ?? 'd/u';
    },
    sortable: true,
  },
  {
    id: 'lastSupplyPrice',
    Header: () => (
      <span>
        Cena
        <br />z o. d.
      </span>
    ),

    accessor: 'lastSupplyPrice',
    sortable: true,
  },
  {
    ...createEditableField('fifoPrice', 'manufacture-products', 'number'),
    sortable: true,
    Header: () => (
      <span>
        Cena
        <br />
        FIFO
      </span>
    ),
  },
  {
    ...createEditableField('packageLength', 'manufacture-products', 'number'),
    sortable: true,
    Header: 'Opakowanie (X)',
  },
  {
    ...createEditableField('packageWidth', 'manufacture-products', 'number'),
    sortable: true,
    Header: 'Opakowanie (Y)',
  },
  {
    ...createEditableField('packageThickness', 'manufacture-products', 'number'),
    sortable: true,
    Header: 'Opakowanie (Z)',
  },
  {
    ...createEditableField('weightTotal', 'manufacture-products', 'number'),
    sortable: true,
    Header: 'Waga brutto',
  },
  {
    id: 'placeQuantities.place.id',
    Header: 'Miejsca magazynowe',
    accessor: 'productQuantity.places',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Place,
    sortable: true,
    filterable: true,
    Cell: ({ row }) => <ProductPlace place={row.original.places}></ProductPlace>,
  },
  {
    id: 'type.id',
    filterable: true,
    sortable: true,
    Header: 'Typ',
    accessor: row => <>{row?.type?.name}</>,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductType,
    minWidth: 160,
  },
  {
    id: 'technology.id',
    filterable: true,
    sortable: true,
    Header: 'Technologia',
    accessor: row => <>{typeof row?.technology !== 'string' && row?.technology?.name}</>,
    Filter: SelectModuleRecordColumnFilter,
    filterModule: TechnologyModule,
    minWidth: 160,
  },
  {
    id: 'active',
    Header: 'Aktywny',
    accessor: ({ active }) => <CheckboxColumn active={active} />,
    filterable: true,
    Filter: SelectColumnFilter,
    filterOptions: [
      { value: null, label: 'Wszystkie' },
      { value: true, label: 'Aktywny' },
      { value: '0', label: 'Nieaktywny' },
    ],
  },
];

const prepareRecordToSave = (record: Partial<Product>) => ({
  ...record,
  image: record.image?.['@id'] ?? record.image,
  group: record.group?.['@id'] ?? record.group,
  type: record.type?.['@id'] ?? record.type,
  version: record.version?.['@id'] ?? record.version,
  species: record.species?.['@id'] ?? record.species,
  series: record.series?.['@id'] ?? record.series,
  kind: record.kind?.['@id'] ?? record.kind,
  unit: record.unit?.['@id'] ?? record.unit,
  color: record.color?.['@id'] ?? record.color,
  technology: record.technology?.['@id'] ?? record.technology,
  technologyFiles: record?.technologyFiles
    ? record.technologyFiles.map(el => ({
        ...el,
        file: el.file['@id'] ?? el.file,
        technologyFileType: el.technologyFileType?.['@id'] ?? el.technologyFileType,
      }))
    : undefined,
  requiredProducts: (record.requiredProducts ?? []).map(rel => ({
    ...rel,
    quantity: rel.quantity ?? 1,
    unit: rel.unit?.['@id'] ?? rel.unit,
    productFilter: rel.productFilter?.['@id'] ?? rel.productFilter,
    product: rel.product ? (rel.product.constructor === Object ? prepareRecordToSave(rel.product) : rel.product) : null,
  })),
});

type ProductCustomItemPaths = {
  downloadTechnologyFiles: CTMPath<CTMApiPathParametersPutItem & { technologyFileTypeId: string }>;
  downloadEbayTemplate: CTMPath<CTMApiPathParametersPutItem>;
};

export const PRODUCT_MODULE_ID = '7ae85ce1-0b95-4c3a-9ff8-f95474af4683';
const module: CTMModule<Product, ProductCustomItemPaths> = {
  id: PRODUCT_MODULE_ID,
  dataClass: 'CTM\\Manufacture\\Entity\\Product',
  urlPrefix: 'manufacture-products',
  name: 'Asortyment',
  role: 'MANUFACTURE_PRODUCT',
  editButtonWrapper: EditWrapper,
  api: {
    item: {
      get: ({ id }) => `/manufacture/products/${id}`,
      put: ({ id }) => `/manufacture/products/${id}`,
      delete: ({ id }) => `/manufacture/products/${id}`,
      downloadTechnologyFiles: ({ id, technologyFileTypeId }) =>
        `/manufacture/products/${id}/download/technologyFileType/${technologyFileTypeId}.zip`,
      downloadEbayTemplate: ({ id }) => `/manufacture/products/${id}/download/ebay.html`,
    },
    collection: {
      get: `/manufacture/products`,
      post: `/manufacture/products`,
    },
  },
  recordLabel: ({ name }) => name,
  form: {
    defaultRecord: () => ({
      '@formValues': {},
      requiredProducts: [],
      technology: null,
      type: null,
    }),
    fieldComponents: {
      ean: EanField,
      suppliers: Suppliers,
      sale: SalePicker,
      subSale: SalePicker,
      specialProfit: SpecialProfit,
    },
    prepareRecordToSave: prepareRecordToSave,
    contextActions: ({ readonly, record, ApiFormComponent }) => (
      <>
        {record.id && <Ebay productId={record.id} />}
        {!readonly && (
          <LockButtonForm ApiFormComponent={ApiFormComponent} locked={record.locked} id={record.id} listRef={null} big={true} />
        )}
        <LabelPicker id={record.id} ean={record.ean} dataClass={module.dataClass} />
      </>
    ),
    customTabs: [
      {
        title: 'Technologie/Pliki',
        component: ProductTechnologies,
      },
      IngredientViewTabConfiguration,
      {
        title: 'Cenniki',
        component: ProductPrices,
      },
      {
        title: 'Dokumenty',
        component: DocumentTab,
        props: { field: 'product', property: 'id' },
      },
      {
        title: 'Masowe pobieranie',
        component: DownloadTab,
      },
    ],
  },
  list: {
    columns: columns,
    defaultFilters: [{ id: 'active', value: true }],
    defaultOrderBy: [{ id: 'labelerId', desc: true }],
    rowStylesCallback: ({ original }) => {
      if (!original.active) {
        return { backgroundColor: '#ffdbdb' };
      }
      return {};
    },
    renderRowSubComponent: ({ row }, modalListActions) => (
      <ChildGrid url={`${row.original['@id']}/required-products?productsOnly=true`} modalListActions={modalListActions} />
    ),
    inlineComponentAppendActions: (row, listRef) => (
      <>
        <LockButton locked={row.locked} id={row.id} listRef={listRef} />
        <SecuredView role={'ROLE_CREATE_MANUFACTURE_PRODUCT'}>
          <CopyItemButton
            copyUrl={`manufacture/products/copy`}
            editUrl={'/modules/manufacture-products/edit/<id>'}
            id={row.id}
            description={'Tworzy nowy produkt na podstawie kopii obecnego.'}
          />
        </SecuredView>
      </>
    ),
    canRenderSubRow: ({ column: { id }, row: { original } }) => {
      if (id === 'actions' || id === 'actionsStickyRight') {
        return false;
      }

      return (original.requiredProductsCount ?? original.product.requiredProductsCount ?? 0) > 0;
    },
    storeFilters: true,
    customExports: [
      {
        fieldName: 'BaseLinker',
        id: 'BaseLinker',
        name: 'BaseLinker',
        url: 'manufacture/products/base-linker-report',
      },
    ],
  },
};

export default module;
