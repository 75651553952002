import { InvoiceModule } from '@Accountancy/Accountancy';
import DepartmentBlock from '@Accountancy/Department/Components/DepartmentBlock';
import { useFetchDepartments } from '@Accountancy/Department/Hooks/useFetchDepartments';
import useFileDownloader from '@Core/Hooks/useFileDownloader';
import useSwalLoader from '@Core/Hooks/useSwalLoader';
import useToastFactory from '@Core/Hooks/useToastFactory';
import { ContextActionsProps } from '@Core/Types/CTMModule';
import { ClientOrder } from '@Ecommerce/ClientOrder';
import GenerateAssemblingOrderButton from '@Ecommerce/Components/GenerateAssemblingOrderButton';
import OpenOrderLinks from '@Ecommerce/Components/OpenOrderLinks';
import SplitOrder from '@Ecommerce/Components/SplitOrder';
import { CalculateOutlined, OpenInNewOutlined, PrintOutlined, ReceiptOutlined, TranslateOutlined } from '@mui/icons-material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Button, IconButton, Menu, MenuItem, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';

type ContextMenuPrintProps = ContextActionsProps<ClientOrder> & {
  closeParent: () => void;
  label: string;
  type: string;
  fileNamePrefix: string;
};

function ContextMenuPrint({ record, closeParent, type, fileNamePrefix, label }: ContextMenuPrintProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const downloadFile = useFileDownloader({
    url: `/ecommerce/client-orders/${record.id}`,
    fileName: `${fileNamePrefix}_${record.number}`.replace(' ', '_'),
  });

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSelectLanguage = (language: string) => {
    downloadFile({ params: { lang: language, type } });
    closeParent();
  };
  if (!record.id) {
    return <></>;
  }

  return (
    <>
      <MenuItem onClick={handleClick}>
        <PrintOutlined />
        {label}
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem>
          <TranslateOutlined />
          Wybierz język
        </MenuItem>
        <MenuItem onClick={() => handleSelectLanguage('pl')}>PL</MenuItem>
        <MenuItem onClick={() => handleSelectLanguage('en')}>EN</MenuItem>
      </Menu>
    </>
  );
}
function GenerateInvoice({ record, closeParent, ApiFormComponent }: ContextActionsProps<ClientOrder> & { closeParent: () => void }) {
  const history = useHistory();
  const toastFactory = useToastFactory();
  const invoiceCreationLoader = useSwalLoader('Trwa generowanie faktury', 'Proszę czekać...');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { data } = useFetchDepartments();
  const generateInvoice = (department: null | string) => {
    if (record.invoices?.length > 0) {
      toastFactory.createWarning(
        <>
          <Typography variant="body1">Zamówienie posiada już fakturę</Typography>
          <Table>
            <TableBody>
              {record.invoices.map(invoice => (
                <TableRow key={invoice['@id']}>
                  <TableCell>{invoice.number}</TableCell>
                  <TableCell>
                    <Button variant="contained" color="warning" onClick={() => window.open(InvoiceModule.showUrl(invoice.id), '_blank')}>
                      <OpenInNewOutlined />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>,
      );
      closeParent();
      return;
    }
    invoiceCreationLoader(
      new Promise((res, rej) => {
        InvoiceModule.api.custom
          .createFromClientOrder({
            clientOrder: record['@id'],
            type: 'VAT',
            department,
          })
          .then(invoice => {
            toastFactory.createSuccess(
              <>
                <Typography variant="body1">Faktura została utworzona</Typography>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => window.open(InvoiceModule.showUrl(invoice.id), '_blank')}
                  className="w-100"
                >
                  <OpenInNewOutlined />
                  Zobacz dokument
                </Button>{' '}
              </>,
            );
            toastFactory.createSuccess(`Nr: ${invoice.number}`);
            closeParent();
            res(true);
            ApiFormComponent.current.refresh();
          })
          .catch(err => {
            toastFactory.createCommonError();
            rej(err);
          });
      }),
    );
  };

  if (!record.id) {
    return <></>;
  }

  if (record.invoices.length > 0) {
    return (
      <MenuItem
        onClick={() => {
          history.push(InvoiceModule.showUrl(record.invoices[0].id));
        }}
      >
        Przejdź do faktury {record.invoices[0].number}
      </MenuItem>
    );
  }

  return (
    <>
      <MenuItem onClick={ev => setAnchorEl(ev.currentTarget)}>
        <ReceiptOutlined />
        Wygeneruj fakturę VAT
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={event => generateInvoice(null)}>Bez przypisanego departamentu</MenuItem>
        {(data ?? []).map(option => (
          <MenuItem key={option['@id']} onClick={() => generateInvoice(option['@id'])}>
            <DepartmentBlock department={option} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

function ContextMenuActions({ record, ...restProps }: ContextActionsProps<ClientOrder>) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const downloadFile = useFileDownloader({
    url: `/ecommerce/client-order/export?id=${record.id}`,
    fileName: `Zamówienie_${record.number.replace('/', '_').replace(' ', '_')}.xlsx`,
    config: {
      headers: {
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    },
  });

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleGetXLSX = () => {
    setAnchorEl(null);
    downloadFile();
  };

  if (!record.id) {
    return <></>;
  }

  return (
    <>
      <IconButton aria-label="more" aria-controls="context-menu" aria-haspopup="true" onClick={handleClick} color={'info'}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="context-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'dotted-button',
        }}
      >
        <ContextMenuPrint
          type="order"
          label="Pobierz plik PDF z zamówieniem"
          fileNamePrefix="Zamówienie"
          record={record}
          {...restProps}
          closeParent={handleClose}
        />
        <ContextMenuPrint
          type="invoice"
          label="Pobierz plik PDF Proforma"
          fileNamePrefix="Proforma"
          record={record}
          {...restProps}
          closeParent={handleClose}
        />
        <MenuItem onClick={handleGetXLSX}>
          <CalculateOutlined /> Pobierz XLSX
        </MenuItem>
        <OpenOrderLinks order={record} />
        <GenerateInvoice record={record} {...restProps} closeParent={handleClose} />
      </Menu>
    </>
  );
}

function ClientOrderContextActions({ record, ...restProps }: ContextActionsProps<ClientOrder>) {
  if (!record.id) {
    return <></>;
  }

  return (
    <>
      <SplitOrder clientOrder={record} />
      <GenerateAssemblingOrderButton clientOrder={record} />
      <ContextMenuActions record={record} {...restProps} />
    </>
  );
}

export default ClientOrderContextActions;
