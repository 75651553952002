import { InvoicePayment, sumPayments } from '@Accountancy/Invoice/Invoice';
import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import DateTimePickerInput from '@Components/Form/MUI/DateTimePickerInput';
import NumberInput from '@Components/Form/MUI/NumberInput';
import TextInput from '@Components/Form/MUI/TextInput';
import { Col, Container } from '@Components/Theme/Grid';
import { DeleteForeverOutlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Button, InputAdornment, Paper, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { FC, useEffect } from 'react';

type PaymentRowProps = InvoiceGroupProps & {
  payment: InvoicePayment;
  index: number;
  readonly: boolean;
  onRowRemove: (index: number) => void;
  onRowUpdate: (index: number, payment: InvoicePayment) => void;
};

const FooterCell = styled(TableCell)(({ theme }) => ({
  fontSize: '0.9rem',
}));
const PlaceholderCell = styled(TableCell)(({ theme }) => ({
  border: 'none',
}));

const PaymentRow: FC<PaymentRowProps> = props => {
  const currency: string = props.values?.[props.fields.currency.id] ?? [];

  const handleInputChange = (value, name: keyof InvoicePayment) => {
    switch (name) {
      default:
        props.onRowUpdate(props.index, { ...props.payment, [name]: value });
        break;
    }
  };

  return (
    <>
      <TableRow>
        <TableCell sx={{ width: 40, textAlign: 'center' }}>
          {props.readonly ? (
            props.index + 1
          ) : (
            <DeleteForeverOutlined color="error" cursor="pointer" onClick={() => props.onRowRemove(props.index)} />
          )}
        </TableCell>
        <TableCell>
          <TextInput disabled={props.readonly} value={props.payment.transaction} onChange={val => handleInputChange(val, 'transaction')} />
        </TableCell>
        <TableCell>
          <TextInput disabled={props.readonly} value={props.payment.paymentType} onChange={val => handleInputChange(val, 'paymentType')} />
        </TableCell>
        <TableCell>
          <NumberInput
            disabled={props.readonly}
            value={props.payment.priceTotalGross}
            onChange={val => handleInputChange(val, 'priceTotalGross')}
            inputProps={{
              endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell>
          <NumberInput
            disabled={true}
            value={parseFloat((props.payment.priceTotalGross * props.values[props.fields.exchangeRate.id]).toFixed(2))}
            inputProps={{
              endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
            }}
          />
        </TableCell>
        <TableCell>
          <DateTimePickerInput
            disabled={props.readonly}
            value={props.payment.receivedDate}
            onChange={val => handleInputChange(val, 'receivedDate')}
          />
        </TableCell>
        <TableCell>
          <TextInput disabled={props.readonly} value={props.payment.description} onChange={val => handleInputChange(val, 'description')} />
        </TableCell>
      </TableRow>
    </>
  );
};

const InvoicePaymentsGroup: FC<InvoiceGroupProps> = props => {
  const payments: InvoicePayment[] = props.values?.[props.fields.payments.id] ?? [];
  const currency: string = props.values?.[props.fields.currency.id] ?? 'PLN';

  const addNewRow = () => {
    props.onUpdate(
      [
        ...payments,
        {
          currency: currency,
          receivedDate: moment().format('YYYY-MM-DD'),
          paymentType: payments?.[payments.length - 1]?.paymentType ?? props.values[props.fields.paymentType.id]?.name ?? null,
        },
      ],
      props.fields.payments.id,
    );
  };

  useEffect(() => {
    if (payments.length === 0) {
      // props.onUpdate([{ quantity: 1 }], props.fields.payments.id);
    }
  }, [payments.length]);

  const handeRemove = (index: number) => {
    props.onUpdate(
      payments.filter((_, i) => i !== index),
      props.fields.payments.id,
    );
  };
  const handleUpdate = (index: number, payment: InvoicePayment) => {
    props.onUpdate(
      payments.map((el, i) => (i === index ? payment : el)),
      props.fields.payments.id,
    );
  };

  return (
    <Paper>
      <Container>
        <Col xs={12}>
          <Typography variant="subtitle1" align="center">
            Płatności
          </Typography>
        </Col>
        <Col xs={12} sx={{ padding: 2 }}>
          <Table padding="none">
            <TableHead>
              <TableRow>
                <TableCell sx={{ width: 40, textAlign: 'center' }}>lp.</TableCell>
                <TableCell sx={{ width: 100 }}>Numer transakcji</TableCell>
                <TableCell sx={{ width: 50 }}>Typ płatności</TableCell>
                <TableCell sx={{ width: 150 }}>Kwota</TableCell>
                <TableCell sx={{ width: 150 }}>Kwota w PLN</TableCell>
                <TableCell sx={{ width: 50 }}>Data wpłynięcia</TableCell>
                <TableCell sx={{ width: 300 }}>Dodatkowy opis</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payments.map((el, index) => (
                <PaymentRow key={index} {...props} payment={el} index={index} onRowRemove={handeRemove} onRowUpdate={handleUpdate} />
              ))}
            </TableBody>
            {!props.readonly && (
              <TableFooter>
                <TableRow>
                  <PlaceholderCell></PlaceholderCell>
                  <TableCell>
                    <Button variant="contained" color="success" startIcon={<AddIcon />} onClick={() => addNewRow()} fullWidth={true}>
                      Rejestruj wpłatę
                    </Button>
                  </TableCell>
                  <PlaceholderCell></PlaceholderCell>
                  <FooterCell>
                    <NumberInput
                      disabled={true}
                      value={parseFloat(sumPayments(payments, 'priceTotalGross').toFixed(2))}
                      inputProps={{
                        endAdornment: <InputAdornment position="end">{currency}</InputAdornment>,
                      }}
                    />
                  </FooterCell>
                  <FooterCell>
                    <NumberInput
                      disabled={true}
                      value={parseFloat((sumPayments(payments, 'priceTotalGross') * props.values[props.fields.exchangeRate.id]).toFixed(2))}
                      inputProps={{
                        endAdornment: <InputAdornment position="end">PLN</InputAdornment>,
                      }}
                    />
                  </FooterCell>
                  <PlaceholderCell></PlaceholderCell>
                  <PlaceholderCell></PlaceholderCell>
                </TableRow>
              </TableFooter>
            )}
          </Table>
        </Col>
      </Container>
    </Paper>
  );
};

export default InvoicePaymentsGroup;
