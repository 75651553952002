import DateTimePickerInput, { DateTimePickerProps } from './MUI/DateTimePickerInput';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, FormFeedback, FormGroup, FormText, Label, Row } from 'reactstrap';
import { useAppSelector } from 'store';

interface DateProps {
  className?: string;
  value?: string | null;
  name: string;
  placeholder?: string;
  helpText?: string;
  label?: string | null;
  size?: { md: number };
  onChange?: (value: string | null, name: string) => void;
  disabled?: boolean;
  disableGroupMargin?: boolean;
  tabIndex?: number;
  ToolbarComponent?: DateTimePickerProps['ToolbarComponent'];
}

const Date: FC<DateProps> = props => {
  const {
    className = 'mb-3',
    value,
    name,
    helpText,
    label = null,
    size = { md: 12 },
    onChange = () => undefined,
    disabled,
    disableGroupMargin,
  } = props;
  const { t } = useTranslation();
  const { violations } = useAppSelector(state => ({
    violations: state.FormErrors.violations,
  }));
  const [selectedValue, setSelectedValue] = useState<string | null>(value ? dayjs(value).format('YYYY-MM-DD') : null);

  const hasError = (): boolean => {
    return violations.hasOwnProperty(name!);
  };
  const getError = (): string => {
    if (hasError()) {
      return violations[name!].join('\n');
    }
    return '';
  };

  useEffect(() => {
    setSelectedValue(value ? dayjs(value).format('YYYY-MM-DD') : null);
  }, [value]);

  const onChangeInput = useCallback(
    (newSelectedValue: string | null) => {
      if (dayjs(value).format('YYYY-MM-DD') !== dayjs(newSelectedValue).format('YYYY-MM-DD')) {
        setSelectedValue(dayjs(newSelectedValue).format('YYYY-MM-DD'));
        onChange(dayjs(newSelectedValue).format('YYYY-MM-DD'), name);
      }
    },
    [value, onChange, name],
  );

  return (
    <Col {...size} className={className}>
      <Row>
        <FormGroup className={classnames({ 'disable-group-margin': disableGroupMargin })} disabled={disabled}>
          {label && <Label>{t(label)}</Label>}
          <DateTimePickerInput
            value={selectedValue}
            name={name}
            onChange={onChangeInput}
            disabled={disabled}
            ToolbarComponent={props.ToolbarComponent}
          />
          {helpText && <FormText>{helpText}</FormText>}
          {hasError() && <FormFeedback>{getError()}</FormFeedback>}
        </FormGroup>
      </Row>
    </Col>
  );
};

export default Date;
