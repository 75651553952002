import { ReactNode } from 'react';
import { Options } from 'react-toast-notifications';
import { useAppDispatch } from 'store';
import { addSingleToast } from 'store/Toast/actions';

function useToastFactory() {
  const dispatch = useAppDispatch();

  const create = (title: ReactNode, config: Options) => {
    dispatch(
      addSingleToast({
        title: title,
        config: config,
      }),
    );
  };

  const createSuccess = (title: ReactNode) => create(title, { appearance: 'success' });
  const createError = (title: ReactNode) => create(title, { appearance: 'error' });
  const createWarning = (title: ReactNode) => create(title, { appearance: 'warning' });
  const createInfo = (title: ReactNode) => create(title, { appearance: 'info' });

  const createCommonError = () => createError('Wystąpił nieoczekiwany błąd. Spróbuj ponownie później.');

  return { create, createSuccess, createError, createCommonError, createWarning, createInfo };
}

export default useToastFactory;
