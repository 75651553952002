import { Box } from '@mui/material';
import { SxProps } from '@mui/system/styleFunctionSx';
import classnames from 'classnames';
import { MouseEventHandler } from 'react';

type ColoredIconBoxProps = {
  icon?: string | null;
  background?: string | null;
  color?: string | null;
  value: null | string;
  sx?: SxProps;
  iconSize?: number;
  reverseColors?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

function ColoredIconBox(props: ColoredIconBoxProps) {
  const { icon, iconSize, background, value, sx, onClick, color, reverseColors } = props;
  const resolvedBackground = reverseColors ? color || '#fff' : background || '#edf0f5';
  const resolvedColor = reverseColors ? background || '#edf0f5' : color || '#fff';
  return (
    <Box
      className="font-size-14 w-100"
      sx={{
        borderRadius: 1,
        padding: icon && !value ? 2 : 1,
        background: resolvedBackground,
        display: 'flex',
        alignItems: 'center',
        justifyContent: icon && !value ? 'space-evenly' : 'start',
        fontWeight: 500,
        color: resolvedColor,
        ...sx,
      }}
      onClick={onClick}
    >
      {icon && (
        <span
          className={classnames(`font-size-${iconSize ?? 16}`)}
          style={{ backgroundColor: resolvedBackground, color: resolvedColor, padding: '0 6px 0 3px' }}
        >
          <i className={`mdi mdi-${icon}`} />
        </span>
      )}
      {value && <span>{value}</span>}
    </Box>
  );
}

export default ColoredIconBox;
