import MenuPicker, { MenuPickerOption, MenuPickerProps } from '@Components/Form/MUI/MenuPicker';
import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';

const TypeOptions: MenuPickerOption<string | null>[] = [
  { value: 'VAT', label: 'Faktura VAT' },
  { value: 'PROFORMA', label: 'Faktura Proforma' },
  { value: 'BILL', label: 'Rachunek' },
  { value: 'RECIPT', label: 'Paragon' },
  { value: 'ADVANCE', label: 'Faktura zaliczkowa' },
  { value: 'FINAL', label: 'Faktura końcowa' },
  { value: 'CORRECTION', label: 'Faktura korekta' },
  { value: 'VAT_MP', label: 'Faktura MP' },
  { value: 'OTHER', label: 'Inna faktura' },
  { value: 'MARGIN', label: 'Faktura marża' },
  { value: 'CORRECTION_NOTE', label: 'Nota korygująca' },
  { value: 'ACCOUNTING_NOT', label: 'Nota księgowa' },
  { value: 'IMPORT_SERVICE', label: 'Import usług' },
  { value: 'IMPORT_SERVICE_EU', label: 'Import usług z UE' },
  { value: 'IMPORT_PRODUCTS', label: 'Import towarów - procedura uproszczona' },
  { value: 'EXPORT_PRODUCTS', label: 'Eksport towarów' },
];

type TypeMenuProps = {
  initValue: MenuPickerProps<string | null>['value'];
  onStatusChange: MenuPickerProps<string | null>['onChange'];
  placeholder?: MenuPickerOption<string | null>;
};

function TypeMenu({ initValue, onStatusChange }: TypeMenuProps) {
  return (
    <MenuPicker<string | null>
      value={initValue}
      options={TypeOptions}
      defaultColor={'#303030'}
      defaultBackground={'#fff'}
      onChange={onStatusChange}
    >
      <ColoredIconBox value={'Typ'} background={'#fff'} color={'#969696'} />
    </MenuPicker>
  );
}

export default TypeMenu;
