import { useModule } from '../../../../../context/ModuleContext';
import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import { InvoiceFields } from '@Accountancy/Invoice/Sections/useInvoiceFields';
import CustomField, { IndexedField } from '@Components/CustomFields/CustomField';
import { useCallback, useMemo } from 'react';

export type InvoiceFieldProps<T = any> = InvoiceGroupProps & {
  fieldKey: keyof InvoiceFields;
  fieldOverride?: Partial<IndexedField>;
  size?: number;
  customProps?: any;
  valueWasChanged?: (field: IndexedField, newValue: null | T, prevValue: null | T) => void;
};

const arr = [];
const obj = {};

function InvoiceField<T>(props: InvoiceFieldProps<T>) {
  const { fields, values, onUpdate, readonly, fieldKey, fieldOverride, size, valueWasChanged, customProps } = props;
  const { module } = useModule();

  const currentValue = useMemo(() => values[fields[fieldKey].id], [values[fields[fieldKey].id]]);

  const handleUpdate = useCallback(
    (value: any, field: string) => {
      valueWasChanged?.(fields[fieldKey], value, currentValue);
      onUpdate(value, field);
    },
    [fields, fieldKey, currentValue],
  );

  const field = useMemo(() => ({ ...fields[fieldKey], ...(fieldOverride ?? {}) }), []);
  return (
    <CustomField
      field={field}
      fields={arr}
      value={currentValue}
      values={obj}
      onUpdate={handleUpdate}
      readonly={readonly}
      components={module.configuration.form?.fieldComponents ?? obj}
      size={size ?? 12}
      recordId={null}
      customProps={customProps}
    />
  );
}

export default InvoiceField;
