import { extractIRIFromRecord } from '../../helpers/ModuleUtils';
import StatusView from '../Core/Components/StatusView';
import ProcurementOrderRows from './Components/ProcurementOrder/ProcurementOrderRows';
import PrintItemButton from '@Components/DataGrid/Button/PrintItemButton';
import { SelectModuleRecordColumnFilter } from '@Components/DataGrid/Filter';
import FilterModuleProperty from '@Components/DataGrid/Filter/FilterModuleProperty';
import Contractor, { Contractor as ContractorModel } from '@Contractor/Contractor';
import StatusPicker from '@Core/Components/StatusPicker';
import StoreValues from '@Ecommerce/Components/StoreValues';
import ProductionOrderQuantity from '@Manufacture/Components/ProductionOrder/ProductionOrderQuantity';
import ProductName from '@Manufacture/Product/List/ProductName';
import ProductType from '@Manufacture/ProductType';
import { ContractorModule, ProductModule } from '@Modules/CTMModules';
import ProcurementOrderDocument from '@Warehouse/Components/ProcurementOrder/ProcurementOrderDocument';
import ProcurementOrderSource from '@Warehouse/Components/ProcurementOrder/ProcurementOrderSource';
import ProcurementOrdersSelection from '@Warehouse/Components/ProcurementOrder/ProcurementOrdersSelection';
import ScheduleButton from '@Warehouse/Components/ProcurementOrder/ScheduleButton';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';

export type ProcurementOrder = CTMRecord & {
  labelerId?: string;
  contractor: string | ContractorModel | undefined;
  rows: any[];
};

const columns: CTMListColumn<ProcurementOrder>[] = [
  {
    id: 'labelerId',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: ({ labelerId }) => 'ZDD ' + labelerId,
  },
  {
    id: 'contractor.id',
    filterable: true,
    sortable: true,
    Header: 'Dostawca',
    accessor: 'contractor.shortName',
    Filter: SelectModuleRecordColumnFilter,
    Cell: ({ row }) => (
      <Link target={'_blank'} to={ContractorModule.editUrl(row.original.contractor.id)}>
        {row.original.contractor.shortName}
      </Link>
    ),
    filterModule: Contractor,
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
  {
    id: 'deadlineAt',
    Header: 'Planowana data dostawy',
    accessor: ({ deadlineAt }) => (deadlineAt ? dayjs(deadlineAt).locale('pl').format('DD.MM.YYYY') : null),
    filterable: true,
    sortable: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'warehouse-procurement-orders'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: FilterModuleProperty,
    filterModuleName: 'warehouse-procurement-orders',
    filterPropertyName: 'status',
  },
  {
    id: 'rows.product.labelerId',
    filterable: true,
    sortable: true,
    Header: 'ID',
    noBreak: true,
    accessor: 'product.labelerId',
    Cell: ({ row }) => (
      <Link target={'_blank'} to={ProductModule.editUrl(row.original.product.id)}>
        {row.original.product.labelerId}
      </Link>
    ),
    filterSearchField: 'rows.product.labelerId',
  },
  {
    id: 'rows.product.type.id',
    filterable: true,
    sortable: true,
    Header: 'Typ',
    accessor: 'product.type.name',
    Cell: ({ row }) => (
      <>
        <i
          style={{ color: `${row.original?.product?.type?.color || '#484f56'}` }}
          className={classnames('mdi font-size-16', {
            [`mdi-${row.original?.product?.type?.icon ?? 'folder'}`]: true,
          })}
        />
        {row.original?.product?.type?.name}
      </>
    ),
    Filter: SelectModuleRecordColumnFilter,
    filterModule: ProductType,
  },
  {
    id: 'rows.product.symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    noBreak: true,
    accessor: 'product.symbol',
    filterSearchField: 'rows.product.symbol',
  },
  {
    id: 'rows.product.image',
    filterable: false,
    sortable: false,
    Header: 'Zdjęcie',
    noBreak: true,
    accessor: 'product.image',
    filterSearchField: 'rows.product.image',
    Cell: ({ row }) => <ProductName image={row.original.product.image} />,
  },
  {
    id: 'rows.product.name',
    filterable: true,
    sortable: true,
    Header: 'Produkt',
    noBreak: true,
    accessor: 'product.name',
    filterSearchField: 'rows.product.name',
  },
  {
    id: 'rows.product.store',
    filterable: false,
    sortable: false,
    Header: 'Stany',
    noBreak: true,
    accessor: '',
    Cell: ({ row }) => <StoreValues key={row.original.id} product={row.original.product} renderFetch={true} withOrdered={false} />,
  },
  {
    id: 'rows.quantity',
    filterable: true,
    sortable: true,
    Header: 'Ilość',
    Cell: ({ row }) => (
      <ProductionOrderQuantity
        key={row.original.rowId}
        id={row.original.rowId}
        quantity={row.original.quantity}
        url={'/warehouse/procurement-order-rows'}
      />
    ),
    noBreak: true,
    accessor: 'quantity',
  },
  {
    id: 'rows.product.unit',
    filterable: false,
    sortable: false,
    Header: 'Jednostka',
    noBreak: true,
    accessor: 'product.unit.relationFieldLabel',
  },
  {
    id: 'source',
    filterable: false,
    sortable: false,
    Header: 'Źródło',
    noBreak: true,
    accessor: '',
    Cell: ({ row }) => <ProcurementOrderSource key={row.original.id} id={row.original.id} />,
  },
];

const module: CTMModule<ProcurementOrder> = {
  id: 'bac5b26c-a70a-4dde-8916-f2a257f6045f',
  dataClass: 'CTM\\Warehouse\\Entity\\ProcurementOrder\\ProcurementOrder',
  urlPrefix: 'warehouse-procurement-orders',
  name: 'Zam. do dostawców',
  role: 'WAREHOUSE_PROCUREMENT_ORDER',
  api: {
    item: {
      get: ({ id }) => `/warehouse/procurement-orders/${id}`,
      put: ({ id }) => `/warehouse/procurement-orders/${id}`,
      delete: ({ id }) => `/warehouse/procurement-orders/${id}`,
    },
    collection: {
      get: `/warehouse/procurement-orders`,
      post: `/warehouse/procurement-orders`,
    },
    custom: {
      schedule: `/warehouse/procurement-orders/schedule`,
    },
  },
  recordLabel: record => record.labelerId,
  form: {
    prepareRecordToSave: record => ({
      ...record,
      contractor: extractIRIFromRecord(record.contractor),
      rows: record.rows?.map(el => ({
        ...el,
        product: el.product?.['@id'] ?? el.product,
        price: Math.round(parseInt(el.price) * 100),
      })),
    }),
    /* onRecordChange: (recordNewState, recordOldState, originalRecord, module, setRecord) => {
      const contractorId = module.fields.find(field => field.propertyPath === 'contractor')?.id;
      const rowsId = module.fields.find(field => field.propertyPath === 'rows')?.id;

      if (!contractorId || !rowsId) {
        return recordNewState;
      }

      if (JSON.stringify(recordNewState) === JSON.stringify(recordOldState)) {
        return recordOldState;
      }

      if (
        JSON.stringify(recordNewState['@formValues'][rowsId]) === JSON.stringify(recordOldState['@formValues'][rowsId]) ||
        !recordNewState['@formValues'][rowsId]?.[0]?.product?.id
      ) {
        return recordNewState;
      }

      ProductSupplierModule.api
        .getAll({ params: { ['product.id']: recordNewState['@formValues'][rowsId][0].product.id } })
        .then(response => {
          const productSupplier = response['hydra:member']?.[0];

          if (!productSupplier) {
            return;
          }

          recordNewState['@formValues'][contractorId] = productSupplier.supplier.id;
          recordNewState['contractor'] = productSupplier.supplier;

          setRecord(prevRecord => {
            prevRecord.contractor = productSupplier.supplier;
            prevRecord['@formValues'][contractorId] = productSupplier.supplier.id;

            return prevRecord;
          });
        });

      return recordNewState;
    },*/
    fieldComponents: {
      status: props => <StatusPicker {...props} moduleName={'warehouse-procurement-orders'} propertyName={'status'} />,
      rows: ProcurementOrderRows,
    },
    contextActions: ({ record }) => {
      return (
        <>
          {record.id && (
            <>
              <ProcurementOrderDocument iri={record?.['@id']} />
              <PrintItemButton
                id={(record.labelerId ?? '').toString()}
                name={'ZDD'}
                downloadUrl={`/warehouse/procurement-orders/${record.id}`}
                description={'Pobierz plik PDF z zamówieniem.'}
                parameters={{}}
                label={true}
                languageSelect={false}
              />
            </>
          )}
        </>
      );
    },
  },
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: false }],
    storeFilters: true,
    board: true,
    boardCardOverride: ({ item, module }) => {
      return (
        <CardBody className="borad-width">
          <div>
            <h5 className="font-size-15">
              <Link to={module.showUrl(item.id)} className="text-dark">
                {item?.labelerId}
              </Link>
            </h5>
          </div>
          <div className="text-end">
            <p className="mb-0 text-muted">{dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
          </div>
        </CardBody>
      );
    },
    prependButton: () => (
      <>
        <ScheduleButton />
      </>
    ),
    inlineComponentPrependActions: (row, listRef) => (
      <>
        {row.status < 1500 && (
          <PrintItemButton
            id={row.id}
            name={'Zamówienie do dostawcy'}
            downloadUrl={`/warehouse/procurement-orders/${row.id}`}
            description={'Pobierz plik PDF z zamówieniem i rozpocznij.'}
            label={false}
            icon={'cursor-default-click'}
            additionalClassnames={['btn-warning']}
            parameters={{ newStatus: 1500 }}
            languageSelect={false}
          />
        )}
        <PrintItemButton
          id={row.id}
          name={'Zamówienie do dostawcy'}
          downloadUrl={`/warehouse/procurement-orders/${row.id}`}
          description={'Pobierz plik PDF z zamówieniem.'}
          label={false}
          languageSelect={false}
        />
      </>
    ),
    selectionActions: ProcurementOrdersSelection,
  },
};

export default module;
