import { ClearButton } from '@Components/DataGrid/Filter/ClearButton';
import MenuMultiplePicker from '@Components/Form/MUI/MenuMultiplePicker';
import MenuPicker from '@Components/Form/MUI/MenuPicker';
import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import { usePropertyValues } from '@Core/Hooks/usePropertyValues';
import { useEffect, useMemo, useState } from 'react';

const FilterModuleProperty = ({ column }) => {
  const multiple = column.filterMultiple === true;
  const [val, setVal] = useState(() => {
    if (multiple) {
      return Array.isArray(column.filterValue) ? column.filterValue : [column.filterValue].filter(Boolean);
    }
    return column.filterValue ?? null;
  });
  const { data: statuses, isLoading } = usePropertyValues(column.filterModuleName, column.filterPropertyName);

  useEffect(() => {
    if (multiple) {
      setVal(Array.isArray(column.filterValue) ? column.filterValue : [column.filterValue].filter(Boolean));
    } else {
      setVal(column.filterValue ?? null);
    }
  }, [column.filterValue]);

  useEffect(() => {
    if (JSON.stringify(val) !== JSON.stringify(column.filterValue)) {
      column.setFilter(val);
    }
  }, [val]);

  const options = useMemo(() => {
    if (!statuses) {
      return [];
    }

    return statuses.map(status => ({
      value: status.value,
      label: status.label,
      background: status.color,
      color: '#fff',
      icon: status.icon,
    }));
  }, [statuses]);

  if (isLoading) {
    return <div className={'module-filter-wrapper'}></div>;
  }

  if (multiple) {
    return (
      <div className={'module-filter-wrapper'}>
        <MenuMultiplePicker
          value={val}
          options={options}
          defaultColor={'#303030'}
          defaultBackground={'#fff'}
          onChange={newVal => setVal(newVal.map(el => el.value))}
        >
          <ColoredIconBox value={column.Header} color="#969696" background="#fff" />
        </MenuMultiplePicker>
        <ClearButton clear={() => setVal([])} isFilter={val.length > 0} />
      </div>
    );
  }

  return (
    <div className={'module-filter-wrapper'}>
      <MenuPicker
        value={val}
        options={options}
        defaultColor={'#303030'}
        defaultBackground={'#fff'}
        onChange={newVal => setVal(newVal.value)}
      >
        <ColoredIconBox value={column.Header} color="#969696" background="#fff" />
      </MenuPicker>
      <ClearButton clear={() => setVal(null)} isFilter={val !== null} />
    </div>
  );
};

export default FilterModuleProperty;
