import { InvoiceModule } from '@Accountancy/Accountancy';
import useDepartment from '@Accountancy/Department/Hooks/useDepartment';
import { useFetchDepartments } from '@Accountancy/Department/Hooks/useFetchDepartments';
import { InvoiceTypes } from '@Accountancy/Invoice/InvoiceTypes';
import MenuPicker from '@Components/Form/MUI/MenuPicker';
import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import { Box } from '@mui/material';
import { memo, useMemo } from 'react';
import { useHistory } from 'react-router';

const AllDepartmentsOption = { label: 'Wszystkie departamenty', background: '#303030', icon: 'bank', value: undefined };

const InvoiceTypeOptions = InvoiceTypes.map(el => ({ label: el.label, value: el.type }));

function InvoiceContextHeader() {
  const history = useHistory();
  const { department, changeDepartment } = useDepartment();
  const { data } = useFetchDepartments();

  const departmentOptions = useMemo(
    () => [AllDepartmentsOption, ...(data ?? []).map(el => ({ label: el.name, icon: el.icon, background: el.color, value: el['@id'] }))],
    [data],
  );

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" className="w-100" style={{ padding: '0 12px' }}>
      <Box>
        <MenuPicker<undefined | string>
          value={department}
          options={departmentOptions}
          onChange={newDepartment => changeDepartment(newDepartment.value)}
        >
          <ColoredIconBox value={'Wszystkie departamenty'} background={'#303030'} color={'#fff'} icon={'bank'} />
        </MenuPicker>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <div style={{ marginRight: 12 }}>
          <MenuPicker<string | null>
            value={null}
            options={InvoiceTypeOptions}
            defaultColor={'#303030'}
            defaultBackground={'#fff'}
            onChange={option => history.push(`${InvoiceModule.createUrl}?income=true&type=${option.value}`)}
          >
            <ColoredIconBox value={'Przychód'} background={'#c8e6c9'} color={'#1b5e20'} icon="plus" />
          </MenuPicker>
        </div>
        <div>
          <MenuPicker<string | null>
            value={null}
            options={InvoiceTypeOptions}
            defaultColor={'#303030'}
            defaultBackground={'#fff'}
            onChange={option => history.push(`${InvoiceModule.createUrl}?income=false&type=${option.value}`)}
          >
            <ColoredIconBox value={'Wydatek'} background={'#ffcdd2'} color={'#b71c1c'} icon={'minus'} />
          </MenuPicker>
        </div>
      </Box>
    </Box>
  );
}

export default memo(InvoiceContextHeader);
