import Loader from '@Components/Theme/Common/Loader';
import { Language as LanguageModel } from '@Core/Language';
import { LanguageModule } from '@Modules/CTMModules';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { FC } from 'react';
import { useQuery } from 'react-query';

const IconMap = {
  en: 'gb',
};

type LanguagePickerProps = {
  onChange: (lang: string) => void;
  onClose: () => void;
  anchorEl: null | HTMLElement;
};

const LanguageMenu: FC<LanguagePickerProps> = ({ onChange, anchorEl, onClose }) => {
  const { data: languages, isLoading } = useQuery<Partial<LanguageModel>[]>(
    ['allLanguages'],
    () => LanguageModule.api.getAll({ params: { pagination: false } }).then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );
  const open = Boolean(anchorEl);

  if (isLoading || !languages) {
    return <Loader />;
  }

  return (
    <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuList>
        {languages.map(language => (
          <MenuItem key={language.isoCode} onClick={() => language.isoCode && onChange(language.isoCode)}>
            <ListItemIcon>
              <span>
                <div
                  className={`fi fi-${IconMap?.[language.isoCode ?? ''] ?? language.isoCode}`}
                  style={{
                    width: '30px',
                    height: '20px',
                  }}
                >
                  &nbsp;
                </div>
              </span>
            </ListItemIcon>
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default LanguageMenu;
