import { InvoiceModule } from '@Accountancy/Accountancy';
import ModuleList from '@Components/Module/ModuleList';
import SecuredView from '@Components/Theme/Common/SecuredView';
import { ModuleProvider } from 'context/ModuleContext';

function Settlements() {
  return (
    <>
      <ModuleProvider value={InvoiceModule}>
        <SecuredView
          role={`${InvoiceModule?.configuration.list?.role ?? InvoiceModule?.configuration.role}|ROLE_SHOW_${
            InvoiceModule?.configuration.list?.role ?? InvoiceModule?.configuration.role
          }`}
          displayError={true}
        >
          <ModuleList
            key={`${InvoiceModule.configuration.urlPrefix}-settlements`}
            moduleName={InvoiceModule.configuration.urlPrefix}
            defaultFilters={undefined}
            overrideFormProps={{}}
            overrideListProps={{
              url: `${InvoiceModule.configuration.list?.url ?? InvoiceModule.api.getAllUrl}?view=settlements`,
              resolveQueryKey: () => `${InvoiceModule.configuration.urlPrefix}-settlements`,
            }}
            useModal={false}
          />
        </SecuredView>
      </ModuleProvider>
    </>
  );
}

export default Settlements;
