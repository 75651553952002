import classnames from 'classnames';
import { useModule } from 'context/ModuleContext';
import { useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';

const VIEW_LIST = 'LIST';
const VIEW_BOARD = 'BOARD';

const ViewSwitch = () => {
  const { module } = useModule();
  const cacheKey = (): string => `_ctm_list_view_V1_${window.btoa(module.configuration.urlPrefix ? module.configuration.urlPrefix : '')}`;
  const [view, setView] = useState<string>(localStorage.getItem(cacheKey()) ?? VIEW_LIST);
  const updateView = (view: string): void => {
    setView(view);
    localStorage.setItem(cacheKey(), view);
    window.dispatchEvent(new Event('list-view'));
  };

  return (
    <ButtonGroup className="ml-1">
      <Button onClick={() => updateView(VIEW_LIST)} className={classnames('btn btn-primary', { 'btn-success': view === VIEW_LIST })}>
        <i className="mdi mdi-view-list" />
      </Button>
      <Button onClick={() => updateView(VIEW_BOARD)} className={classnames('btn btn-primary', { 'btn-success': view === VIEW_BOARD })}>
        <i className="mdi mdi-view-dashboard" />
      </Button>
    </ButtonGroup>
  );
};

export default ViewSwitch;
