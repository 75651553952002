import { usePropertyValues } from '../Hooks/usePropertyValues';
import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import { Tooltip } from '@mui/material';
import { FC } from 'react';

const Status: FC<{ moduleName: string; propertyName?: string; status?: number; iconOnly?: boolean }> = ({
  moduleName,
  propertyName,
  status,
  iconOnly,
}) => {
  if (!propertyName) {
    throw new Error('propertyName is required');
  }

  const { data: statuses } = usePropertyValues(moduleName, propertyName);

  if (status === null) {
    return null;
  }

  const item = statuses?.find(item => item.value === status);

  if (!item) {
    return <ColoredIconBox value="N/A" background="#cbd0d3" color="#fff" />;
  }

  return (
    <Tooltip title={item?.label}>
      <div>
        <ColoredIconBox
          value={iconOnly ? null : item.label}
          background={item.color}
          color={'#fff'}
          icon={item?.icon}
          iconSize={iconOnly ? 20 : 16}
          sx={iconOnly ? { padding: 0, minWidth: 'initial', textAlign: 'center' } : {}}
        />
      </div>
    </Tooltip>
  );
};

export default Status;

Status.defaultProps = {
  propertyName: 'status',
};
