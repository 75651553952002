import { ClearButton } from './ClearButton';
import ListPicker from 'components/Form/ListPicker';
import RelationSinglePicker from 'components/Form/RelationSinglePicker';
import SelectSingleAPI from 'components/Form/SelectSingleAPI';
import { useModuleContext } from 'context/ModulesContext';
import { get } from 'helpers/Axios';
import debounce from 'lodash.debounce';
import { useEffect, useMemo, useRef, useState } from 'react';
import { type Column } from 'react-table';
import { Input } from 'reactstrap';

export const Filter = ({ column }) => {
  return <div className={'module-filter-wrapper'}>{column.canFilter && column.render('Filter')}</div>;
};

export const DefaultColumnFilter = ({ column: { filterValue, setFilter, Header, autofocus } }: { column: Column }) => {
  const input = useRef<HTMLInputElement>(null);
  const [val, setVal] = useState(filterValue);

  useEffect(() => {
    if (autofocus && input.current) {
      input.current.focus();
    }
  }, [input.current, autofocus]);

  const debouncedEventHandler = useMemo(
    () =>
      debounce(newVal => {
        setFilter(newVal);
      }, 450),
    [],
  );

  useEffect(() => {
    debouncedEventHandler(val);
  }, [debouncedEventHandler, val]);

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, []);

  useEffect(() => {
    setVal(filterValue);
  }, [filterValue]);

  return (
    <div className={'module-filter-wrapper'}>
      <Input
        innerRef={input}
        value={val || ''}
        onChange={e => {
          setVal(e.target.value || undefined);
        }}
        placeholder={`${Header}...`}
      />
      <ClearButton clear={() => setVal('')} isFilter={Boolean(val)} />
    </div>
  );
};

export const SelectApiColumnFilter = ({ column }: { column: Column }) => {
  const fetchValue = async () => {
    setVal(await column.filterFetchByValue(column.filterValue));
  };

  useEffect(() => {
    if (column.filterValue) {
      fetchValue();
    }
  }, []);

  useEffect(() => {
    if (!column.filterValue) {
      setVal(null);
    } else if (!val || val[column.filterSearchField ?? 'id'] !== column.filterValue) {
      fetchValue();
    }
  }, [column.filterValue]);

  const [val, setVal] = useState(column.filterValue ?? null);
  return (
    <div className={'module-filter-wrapper'}>
      <SelectSingleAPI
        className="_"
        disableGroupMargin={true}
        size={{ md: 12 }}
        value={val}
        name={'search-field'}
        onChange={(value, name, objVal) => {
          if (objVal) {
            setVal(objVal);
            column.setFilter(objVal[column.filterSearchField ?? 'id']);
          } else {
            setVal(null);
            column.setFilter(null);
          }
        }}
        placeholder={`${column.Header}...`}
        endpoint={(column.filterSearchUrl ?? '/') + '?relationFieldLabel'}
        buildQuery={v => ({ searchRelationFieldLabel: v })}
        optionLabel="relationFieldLabel"
        isClearable={true}
      />
      <ClearButton
        clear={() => {
          column.setFilter(null);
          setVal(null);
        }}
        isFilter={Boolean(val)}
      />
    </div>
  );
};

export const SelectModuleRecordColumnFilter = ({ column }: { column: Column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);
  const ModuleContext = useModuleContext(column.filterModuleName ?? column.filterModule.urlPrefix ?? '');

  useEffect(() => {
    if (column.filterModule && column.filterValue && typeof val === 'string') {
      get(column.filterModule.api.item.get({ id: val }))
        .then(filterObject => {
          setVal(filterObject);
        })
        .catch(err => {
          console.error('Ann error while fetching filter default value', err);
          column.setFilter(undefined);
        });
    }
  }, [val, column]);

  useEffect(() => {
    if (!column.filterValue) {
      setVal(null);
    }
  }, [column.filterValue]);

  if (!ModuleContext) {
    return null;
  }

  return (
    <div className={'module-filter-wrapper'}>
      <RelationSinglePicker
        moduleName={ModuleContext.configuration.urlPrefix}
        name={ModuleContext.configuration.urlPrefix}
        value={val}
        inputProps={{ placeholder: `${column.Header}...` }}
        onChange={(mappedVal, name, gridRow) => {
          setVal(gridRow);
          column.setFilter(mappedVal);
        }}
        fetchValue={value => ModuleContext.api.get({ value })}
        optionLabel="relationFieldLabel"
        optionTrackBy="id"
        disableGroupMargin={true}
        className={''}
      />
      <ClearButton
        clear={() => {
          column.setFilter(null);
          setVal(null);
        }}
        isFilter={Boolean(val)}
      />
    </div>
  );
};

export const SelectColumnFilter = ({ column }: { column: Column }) => {
  const [val, setVal] = useState(column.filterValue ?? null);

  useEffect(() => {
    setVal(column.filterValue ?? null);
  }, [column.filterValue]);

  return (
    <div className={'module-filter-wrapper'}>
      <ListPicker
        className="_"
        size={{ md: 12 }}
        value={val}
        options={column.filterOptions}
        name={'search-field'}
        onChange={value => {
          if (value) {
            setVal(value);
            column.setFilter(value);
          } else {
            setVal(null);
            column.setFilter(null);
          }
        }}
        placeholder={`${column.Header}...`}
        optionTrackBy="value"
        optionLabel="label"
        isClearable={true}
      />
    </div>
  );
};
