export type InvoiceStatus = 'ISSUED' | 'PAID' | 'PARTIAL' | 'REJECTED';

export type InvoiceStatusRow = { type: InvoiceStatus; label: string };
export const InvoiceStatuses: InvoiceStatusRow[] = [
  { type: 'ISSUED', label: 'Nierozliczona' },
  { type: 'PAID', label: 'Rozliczona' },
  { type: 'PARTIAL', label: 'Częściowo rozl.' },
  { type: 'REJECTED', label: 'Odrzucona' },
];

export const statusLabel = (status: InvoiceStatus): string => {
  return InvoiceStatuses.find(s => s.type === status)?.label ?? 'Nieznany';
};
