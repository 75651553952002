import useToastFactory from '@Core/Hooks/useToastFactory';
import { AxiosRequestConfig } from 'axios';
import { axiosApi } from 'helpers/Axios';
import { downloadBlobFile } from 'helpers/File';
import Swal, { SweetAlertOptions } from 'sweetalert2';

const defaultAxiosConfig: AxiosRequestConfig = {
  responseType: 'blob',
  headers: {
    Accept: 'application/pdf',
  },
};

type FileDownloaderProps = {
  url: string;
  fileName: string;
  config?: AxiosRequestConfig;
  onError?: (error: any) => void;
  onSuccess?: (data: any) => void;
};

const useFileDownloader = (config: FileDownloaderProps) => {
  const toastFactory = useToastFactory();
  const defaultOnSuccess = (data: any) => {
    downloadBlobFile(data, config.fileName);
  };
  return (actionConfig: AxiosRequestConfig = {}) => {
    Swal.fire<any>({
      title: 'Pobieranie pliku',
      text: 'Plik jest pobierany, proszę czekać',
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    } as SweetAlertOptions);

    axiosApi
      .get(config.url, Object.assign({}, defaultAxiosConfig, config.config, actionConfig))
      .then(response => {
        toastFactory.createSuccess('Plik został pobrany');
        (config.onSuccess ?? defaultOnSuccess)(response.data);
      })
      .catch(config?.onError ?? console.error)
      .finally(() => Swal.close());
  };
};

export default useFileDownloader;
