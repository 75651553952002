import Loader from '@Components/Theme/Common/Loader';
import useCurrentUser from '@Employee/Employee/Hooks/UseCurrentUser';
import { DocumentModule } from '@Modules/CTMModules';
import { useWarehouse } from '@Warehouse/Warehouse';
import { useDocumentTypes } from 'pages/Warehouse/Document/useDocumentTypes';
import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

const ProcurementOrderSource: FC<{ iri: string }> = ({ iri }) => {
  const currentUser = useCurrentUser();
  const { isLoading: isLoadingDocumentTypes, data: documentTypes } = useDocumentTypes();
  const { isLoading: isLoadingWarehouse, data: warehouse } = useWarehouse(currentUser.defaults?.warehouse ?? '');
  const [document, setDocument] = useState<any | null>(null);

  useEffect(() => {
    if (!iri) {
      return;
    }

    DocumentModule.api
      .getAll({
        params: {
          owner: iri,
          status: 2000,
        },
      })
      .then(response => {
        response['hydra:member'].forEach((document: any) => {
          setDocument(document);
        });
      });
  }, [iri]);

  if (isLoadingDocumentTypes || isLoadingWarehouse) {
    return <Loader />;
  }

  if (document) {
    return (
      <Link className={'btn btn-success ml-1'} to={`/warehouse/documents/${document.id}/show`}>
        {document.number}
      </Link>
    );
  }

  return (
    <>
      {documentTypes
        ?.filter((documentType: any) => ['PZ', 'PW'].indexOf(documentType.code) > -1)
        ?.map((documentType: any) => (
          <Link
            key={documentType.id}
            className={'btn btn-success ml-1'}
            to={`/warehouse/documents/add/${documentType.id}?warehouse=${warehouse?.id}&owner=${iri}`}
          >
            Utwórz {documentType.code}
          </Link>
        ))}
    </>
  );
};

export default ProcurementOrderSource;
