import AccountancyModules from '@Accountancy/Accountancy';
import Contractor, { Contractor as ContractorModel } from '@Contractor/Contractor';
import Inquiry, { Inquiry as InquiryModel } from '@Contractor/Inquiry';
import Lead, { Lead as LeadModel } from '@Contractor/Lead';
import ShipmentAddress, { ShipmentAddress as ShipmentAddressModel } from '@Contractor/ShipmentAddress';
import CRUDModule from '@Core/CRUDModule';
import CompanyBankAccount, { BankAccount as CompanyBankAccountModel } from '@Core/Company/BankAccount';
import Country, { Country as CountryModel } from '@Core/Country';
import Dictionary, { Dictionary as DictionaryModel } from '@Core/Dictionary';
import DictionaryCategory, { DictionaryCategory as DictionaryCategoryModel } from '@Core/DictionaryCategory';
import DictionaryValue, { DictionaryValue as DictionaryValueModel } from '@Core/DictionaryValue';
import ExportConfig, { ExportConfig as ExportConfigModel } from '@Core/ExportConfig';
import Language, { Language as LanguageModel } from '@Core/Language';
import LogType, { Log as LogTypeModel } from '@Core/Log';
import CoreModule, { Module as CoreModuleModel } from '@CustomFields/CoreModule';
import Field, { Field as FieldModel } from '@CustomFields/Field';
import SystemField, { SystemField as SystemFieldModel } from '@CustomFields/SystemField';
import Template, { Template as TemplateModel } from '@CustomFields/Template';
import DeliveryFee, { DeliveryFee as DeliveryFeeModel } from '@Delivery/DeliveryFee';
import DeliveryMethod, { DeliveryMethod as DeliveryMethodModel } from '@Delivery/DeliveryMethod';
import Client, { Client as ClientModel } from '@Ecommerce/Client';
import ClientOrder, { ClientOrder as ClientOrderModel } from '@Ecommerce/ClientOrder';
import ClientOrderSource, { ClientOrderSource as ClientOrderSourceModel } from '@Ecommerce/ClientOrderSource';
import ClientRegistrationRequest, {
  ClientRegistrationRequest as ClientRegistrationRequestModel,
} from '@Ecommerce/ClientRegistrationRequest';
import PaymentType, { PaymentTypeModel } from '@Ecommerce/PaymentType';
import ProductCollection, { ProductCollection as ProductCollectionModel } from '@Ecommerce/ProductCollection';
import Transaction, { Transaction as TransactionModel } from '@Ecommerce/Transaction';
import EmployeeModules from '@Employee/Employee';
import BaseLinkerIntegration, { BaseLinkerIntegration as BaseLinkerIntegrationModel } from '@Integration/BaseLinkerIntegration';
import Errand from '@Manufacture/Errand';
import Label, { Label as LabelModel } from '@Manufacture/Label/Label';
import Machine, { Machine as MachineModel } from '@Manufacture/Machine';
import Tariff, { Tariff as TariffModel } from '@Manufacture/Price/Tariff';
import Product, { Product as ProductModel } from '@Manufacture/Product';
import ProductColor, { ProductColor as ProductColorModel } from '@Manufacture/ProductColor';
import ProductColorType, { ProductColorType as ProductColorTypeModel } from '@Manufacture/ProductColorType';
import ProductFilter, { ProductFilter as ProductFilterModel } from '@Manufacture/ProductFilter';
import ProductGroup from '@Manufacture/ProductGroup';
import ProductKind, { ProductKind as ProductKindModel } from '@Manufacture/ProductKind';
import ProductSeries, { ProductSeries as ProductSeriesModel } from '@Manufacture/ProductSeries';
import ProductSpecies, { ProductSpecies as ProductSpeciesModel } from '@Manufacture/ProductSpecies';
import ProductSupplier, { ProductSupplier as ProductSupplierModel } from '@Manufacture/ProductSupplier';
import ProductType, { ProductType as ProductTypeModel } from '@Manufacture/ProductType';
import ProductVersion, { ProductVersion as ProductVersionModel } from '@Manufacture/ProductVersion';
import ProductionDemand, { ProductionDemand as ProductionDemandModel } from '@Manufacture/ProductionDemand';
import ProductionOrder, { ProductionOrder as ProductionOrderModel } from '@Manufacture/ProductionOrder';
import Technology from '@Manufacture/Technology';
import { ErrandType as ErrandModel } from '@Manufacture/Types/Errand';
import { ProductGroupType as ProductGroupModel } from '@Manufacture/Types/ProductGroup';
import { Technology as TechnologyModel } from '@Manufacture/Types/Technology';
import Unit, { Unit as UnitModel } from '@Manufacture/Unit';
import Workplace, { Workplace as WorkplaceModel } from '@Manufacture/Workplace';
import Slide, { Slide as SlideModel } from '@Store/Slide';
import Task from '@TaskManagement/Task';
import { Task as TaskModel } from '@TaskManagement/Types/Task';
import AssemblingOrder, { AssemblingOrder as AssemblingOrderModel } from '@Warehouse/AssemblingOrder';
import Box, { Box as BoxModel } from '@Warehouse/Box';
import Document, { Document as DocumentModel } from '@Warehouse/Document';
import DocumentNumber, { DocumentNumber as DocumentNumberModel } from '@Warehouse/DocumentNumber';
import DocumentType, { DocumentType as DocumentTypeModel } from '@Warehouse/DocumentType';
import Place, { Place as PlaceModel } from '@Warehouse/Place';
import PlaceEvent, { PlaceEvent as PlaceEventModel } from '@Warehouse/PlaceEvent';
import PlaceQuantity, { PlaceQuantity as PlaceQuantityModel } from '@Warehouse/PlaceQuantity';
import ProcurementOrder, { ProcurementOrder as ProcurementOrderModel } from '@Warehouse/ProcurementOrder';
import ProcurementOrderExport, { ProcurementOrderExport as ProcurementOrderExportModel } from '@Warehouse/ProcurementOrderExport';
import ProcurementOrderNotification, {
  ProcurementOrderNotification as ProcurementOrderNotificationModel,
} from '@Warehouse/ProcurementOrderNotification';
import Shipment, { Shipment as ShipmentModel } from '@Warehouse/Shipment';
import Warehouse, { Warehouse as WarehouseModel } from '@Warehouse/Warehouse';
import AccountancyValueAddedTax, { ValueAddedTax as AccountancyValueAddedTaxModel } from 'Modules/Core/Accountancy/ValueAddedTax';
import Image, { Image as ImageModel } from 'Modules/CustomFields/Image';

const modules: {
  [key: string]: CRUDModule<any>;
} = {
  ...AccountancyModules,
  ...EmployeeModules,
};

export const ProductModule = new CRUDModule<ProductModel>(Product);
export const ErrandModule = new CRUDModule<ErrandModel>(Errand);
export const AssemblingOrderModule = new CRUDModule<AssemblingOrderModel>(AssemblingOrder);
export const ShipmentModule = new CRUDModule<ShipmentModel>(Shipment);
export const ProductTypeModule = new CRUDModule<ProductTypeModel>(ProductType);
export const TechnologyModule = new CRUDModule<TechnologyModel>(Technology);
export const ProductColorModule = new CRUDModule<ProductColorModel>(ProductColor);
export const ProductFilterModule = new CRUDModule<ProductFilterModel>(ProductFilter);
export const ProductKindModule = new CRUDModule<ProductKindModel>(ProductKind);
export const ProductSpeciesModule = new CRUDModule<ProductSpeciesModel>(ProductSpecies);
export const ProductSupplierModule = new CRUDModule<ProductSupplierModel>(ProductSupplier);
export const ProductSeriesModule = new CRUDModule<ProductSeriesModel>(ProductSeries);
export const ProductColorTypeModule = new CRUDModule<ProductColorTypeModel>(ProductColorType);
export const ProductVersionModule = new CRUDModule<ProductVersionModel>(ProductVersion);
export const ProductGroupModule = new CRUDModule<ProductGroupModel>(ProductGroup);
export const UnitModule = new CRUDModule<UnitModel>(Unit);
export const DocumentTypeModule = new CRUDModule<DocumentTypeModel>(DocumentType);
export const DocumentModule = new CRUDModule<DocumentModel>(Document);
export const DocumentNumberModule = new CRUDModule<DocumentNumberModel>(DocumentNumber);
export const ContractorModule = new CRUDModule<ContractorModel>(Contractor);
export const ShipmentAddressModule = new CRUDModule<ShipmentAddressModel>(ShipmentAddress);
export const LeadModule = new CRUDModule<LeadModel>(Lead);
export const InquiryModule = new CRUDModule<InquiryModel>(Inquiry);
export const PlaceModule = new CRUDModule<PlaceModel>(Place);
export const PlaceEventModule = new CRUDModule<PlaceEventModel>(PlaceEvent);
export const WarehouseModule = new CRUDModule<WarehouseModel>(Warehouse);
export const DictionaryModule = new CRUDModule<DictionaryModel>(Dictionary);
export const LanguageModule = new CRUDModule<LanguageModel, LanguageModel>(Language);
export const CountryModule = new CRUDModule<CountryModel>(Country);
export const LogModule = new CRUDModule<LogTypeModel>(LogType);
export const DictionaryValueModule = new CRUDModule<DictionaryValueModel>(DictionaryValue);
export const MachineModule = new CRUDModule<MachineModel>(Machine);
export const WorkplaceModule = new CRUDModule<WorkplaceModel>(Workplace);
export const ClientOrderSourceModule = new CRUDModule<ClientOrderSourceModel>(ClientOrderSource);
export const DictionaryCategoryModule = new CRUDModule<DictionaryCategoryModel>(DictionaryCategory);
export const TaskModule = new CRUDModule<TaskModel>(Task);
export const LabelModule = new CRUDModule<LabelModel>(Label);
export const CoreModuleModule = new CRUDModule<CoreModuleModel>(CoreModule);
export const ExportConfigModule = new CRUDModule<ExportConfigModel>(ExportConfig);
export const TemplateModule = new CRUDModule<TemplateModel>(Template);
export const FieldModule = new CRUDModule<FieldModel>(Field);
export const SystemFieldModule = new CRUDModule<SystemFieldModel>(SystemField);
export const ClientOrderModule = new CRUDModule<ClientOrderModel>(ClientOrder);
export const PaymentTypeModule = new CRUDModule<PaymentTypeModel>(PaymentType);
export const TransactionModule = new CRUDModule<TransactionModel>(Transaction);
export const ClientModule = new CRUDModule<ClientModel>(Client);
export const ClientRegistrationRequestModule = new CRUDModule<ClientRegistrationRequestModel>(ClientRegistrationRequest);
export const ProductCollectionModule = new CRUDModule<ProductCollectionModel>(ProductCollection);
export const TariffModule = new CRUDModule<TariffModel>(Tariff);
export const CompanyBankAccountModule = new CRUDModule<CompanyBankAccountModel>(CompanyBankAccount);
export const AccountancyValueAddedTaxModule = new CRUDModule<AccountancyValueAddedTaxModel>(AccountancyValueAddedTax);
export const PlaceQuantityModule = new CRUDModule<PlaceQuantityModel>(PlaceQuantity);
export const ProductionOrderModule = new CRUDModule<ProductionOrderModel>(ProductionOrder);
export const ProcurementOrderNotificationModule = new CRUDModule<ProcurementOrderNotificationModel>(ProcurementOrderNotification);
export const ProductionDemandModule = new CRUDModule<ProductionDemandModel>(ProductionDemand);
export const BoxModule = new CRUDModule<BoxModel>(Box);
export const SlideModule = new CRUDModule<SlideModel>(Slide);
export const DeliveryMethodModule = new CRUDModule<DeliveryMethodModel>(DeliveryMethod);
export const DeliveryFeeModule = new CRUDModule<DeliveryFeeModel>(DeliveryFee);
export const BaseLinkerIntegrationModule = new CRUDModule<BaseLinkerIntegrationModel>(BaseLinkerIntegration);
export const ImageModule = new CRUDModule<ImageModel>(Image);
export const ProcurementOrderModule = new CRUDModule<ProcurementOrderModel>(ProcurementOrder);
export const ProcurementOrderExportModule = new CRUDModule<ProcurementOrderExportModel>(ProcurementOrderExport);

modules[Errand.urlPrefix] = ErrandModule;
modules[AssemblingOrder.urlPrefix] = AssemblingOrderModule;
modules[Shipment.urlPrefix] = ShipmentModule;
modules[Product.urlPrefix] = ProductModule;
modules[ProductType.urlPrefix] = ProductTypeModule;
modules[Technology.urlPrefix] = TechnologyModule;
modules[ProductColor.urlPrefix] = ProductColorModule;
modules[ProductFilter.urlPrefix] = ProductFilterModule;
modules[ProductKind.urlPrefix] = ProductKindModule;
modules[ProductSpecies.urlPrefix] = ProductSpeciesModule;
modules[ProductSupplier.urlPrefix] = ProductSupplierModule;
modules[ProductSeries.urlPrefix] = ProductSeriesModule;
modules[ProductColorType.urlPrefix] = ProductColorTypeModule;
modules[ProductVersion.urlPrefix] = ProductVersionModule;
modules[ProductGroup.urlPrefix] = ProductGroupModule;
modules[Unit.urlPrefix] = UnitModule;
modules[DocumentType.urlPrefix] = DocumentTypeModule;
modules[Document.urlPrefix] = DocumentModule;
modules[DocumentNumber.urlPrefix] = DocumentNumberModule;
modules[Contractor.urlPrefix] = ContractorModule;
modules[ShipmentAddress.urlPrefix] = ShipmentAddressModule;
modules[Lead.urlPrefix] = LeadModule;
modules[Inquiry.urlPrefix] = InquiryModule;
modules[Place.urlPrefix] = PlaceModule;
modules[PlaceEvent.urlPrefix] = PlaceEventModule;
modules[Warehouse.urlPrefix] = WarehouseModule;
modules[Dictionary.urlPrefix] = DictionaryModule;
modules[LogType.urlPrefix] = LogModule;
modules[DictionaryValue.urlPrefix] = DictionaryValueModule;
modules[Machine.urlPrefix] = MachineModule;
modules[Workplace.urlPrefix] = WorkplaceModule;
modules[DictionaryCategory.urlPrefix] = DictionaryCategoryModule;
modules[Language.urlPrefix] = LanguageModule;
modules[Country.urlPrefix] = CountryModule;
modules[Task.urlPrefix] = TaskModule;
modules[Label.urlPrefix] = LabelModule;
modules[CoreModule.urlPrefix] = CoreModuleModule;
modules[ExportConfig.urlPrefix] = ExportConfigModule;
modules[Template.urlPrefix] = TemplateModule;
modules[Field.urlPrefix] = FieldModule;
modules[SystemField.urlPrefix] = SystemFieldModule;
modules[ClientOrder.urlPrefix] = ClientOrderModule;
modules[Transaction.urlPrefix] = TransactionModule;
modules[Client.urlPrefix] = ClientModule;
modules[ClientRegistrationRequest.urlPrefix] = ClientRegistrationRequestModule;
modules[ProductCollection.urlPrefix] = ProductCollectionModule;
modules[Tariff.urlPrefix] = TariffModule;
modules[CompanyBankAccount.urlPrefix] = CompanyBankAccountModule;
modules[AccountancyValueAddedTax.urlPrefix] = AccountancyValueAddedTaxModule;
modules[PlaceQuantity.urlPrefix] = PlaceQuantityModule;
modules[ProductionOrder.urlPrefix] = ProductionOrderModule;
modules[ProcurementOrderNotification.urlPrefix] = ProcurementOrderNotificationModule;
modules[ProductionDemand.urlPrefix] = ProductionDemandModule;
modules[Box.urlPrefix] = BoxModule;
modules[Slide.urlPrefix] = SlideModule;
modules[Transaction.urlPrefix] = TransactionModule;
modules[DeliveryMethod.urlPrefix] = DeliveryMethodModule;
modules[DeliveryFee.urlPrefix] = DeliveryFeeModule;
modules[Image.urlPrefix] = ImageModule;
modules[BaseLinkerIntegration.urlPrefix] = BaseLinkerIntegrationModule;
modules[ProcurementOrder.urlPrefix] = ProcurementOrderModule;
modules[ProcurementOrderExport.urlPrefix] = ProcurementOrderExportModule;
modules[ClientOrderSource.urlPrefix] = ClientOrderSourceModule;
modules[PaymentType.urlPrefix] = PaymentTypeModule;

export const dataClassModulesMap = Object.keys(modules).reduce((prev, curr) => {
  prev[modules[curr].configuration.dataClass] = modules[curr].configuration.id;
  return prev;
}, {});

export default modules;
