import { useAppSelector } from '../../../store';
import LabelerId from '@Components/CustomFields/LabelerId';
import CharsCounter from '@Components/CustomFields/Shared/CharsCounter';
import LanguagePicker from '@Core/Components/LanguagePicker';
import BaseScalarInput, { BaseScalarInputType } from 'components/CustomFields/FieldTypes/BaseScalarInput';
import Editor from 'components/Form/Editor';

const TextareaField: BaseScalarInputType = props => {
  const { field, value, onChange, type, inputProps = {}, readonly, errorKey, customProps = {} } = props;
  const selectedLanguage = useAppSelector(state => state.Root.language) as string;

  const isReadonly = readonly || field.config.readonly;

  const onEditorChange = value => {
    onChange(value, field.id);
  };

  const overriddenType = customProps?.overriddenEditor ?? field.config.editor;

  switch (overriddenType) {
    case 'CKEDITOR':
      return (
        <>
          <div className={'cf-text-editor'}>
            <Editor
              key={selectedLanguage}
              label={
                `${field.name}`.length > 0 && (
                  <div style={{ display: 'flex', position: 'relative', width: '100%', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', position: 'relative' }}>
                      <div>{field.multiLanguage && <LanguagePicker isReadonly={isReadonly} />}</div>
                      <div>
                        <LabelerId labelerId={field.labelerId} />
                        {field.name || 'Nazwa pola'}
                      </div>
                    </div>
                  </div>
                )
              }
              value={value}
              name={field.id}
              type={type}
              onChange={onEditorChange}
              inputProps={{ ...inputProps, tabIndex: field._tabindex }}
              disabled={isReadonly}
              labelBadge={field.position}
              errorKey={errorKey}
              disableGroupMargin={true}
              fullWeight={true}
              fieldId={field.id}
              {...customProps}
            />
          </div>
          <CharsCounter maxChars={field.config.limit ?? 0} value={props.value ?? ''} />
        </>
      );
    default:
      return BaseScalarInput({
        ...props,
        readonly: isReadonly,
        customProps: { fullHeight: true, fullInputHeight: true },
        type: 'textarea',
        inputProps: { style: { height: '100%' } },
        nextRowContent: <CharsCounter maxChars={field.config.limit ?? 0} value={props.value ?? ''} />,
      });
  }
};

export default TextareaField;
