import { ClearButton } from '@Components/DataGrid/Filter/ClearButton';
import MenuPicker, { MenuPickerOption } from '@Components/Form/MUI/MenuPicker';
import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import { usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import { useEffect, useMemo, useState } from 'react';

export const SelectStatusColumnFilter = ({ column }) => {
  const [val, setVal] = useState<string | number | null>(column.filterValue ?? null);

  const { data: statuses, isLoading } = usePropertyValues('ecommerce-client-order', 'status');

  const options = useMemo<MenuPickerOption<string | number | null>[]>(() => {
    if (!statuses) {
      return [];
    }

    return statuses.map(status => ({
      value: status.value,
      label: status.label,
      background: status.color,
      color: '#fff',
      icon: status.icon,
    }));
  }, [statuses]);

  useEffect(() => {
    column.setFilter(val);
  }, [val]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className={'module-filter-wrapper'}>
      <MenuPicker<string | number | null>
        value={val}
        options={options}
        defaultColor={'#303030'}
        defaultBackground={'#fff'}
        onChange={newVal => setVal(newVal.value)}
      >
        <ColoredIconBox value={'Status'} background={'#fff'} color={'#969696'} />
      </MenuPicker>
      <ClearButton clear={() => setVal(null)} isFilter={val !== null} />
    </div>
  );
};

export default SelectStatusColumnFilter;
