import { Box, Typography } from '@mui/material';

function FakeInputWrapper({ label, children, ...props }: { label: string; children: React.ReactNode }) {
  return (
    <Box {...props} sx={{ display: 'flex', flexDirection: 'column', gap: 1, marginTop: 0, position: 'relative' }}>
      <Typography
        variant="body1"
        sx={{
          mb: 0.5,
          position: 'absolute',
          top: '-8px',
          left: '8px',
          color: '#303030',
          fontWeight: 300,
          fontSize: 12,
          background: '#fff',
        }}
      >
        {label}
      </Typography>
      <Box sx={{ border: '1px solid #ccc', borderRadius: 2, padding: 0 }}>{children}</Box>
    </Box>
  );
}

export default FakeInputWrapper;
