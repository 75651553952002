import MenuPicker, { MenuPickerOption, MenuPickerProps } from '@Components/Form/MUI/MenuPicker';
import ColoredIconBox from '@Components/Theme/Common/ColoredIconBox';
import { useMemo } from 'react';

const StatusOptions: MenuPickerOption<string | null>[] = [
  { value: 'ISSUED', label: 'Wystawiona' },
  { value: 'PAID', label: 'Opłacona' },
  { value: 'PARTIAL', label: 'Częściowo opł.' },
  { value: 'REJECTED', label: 'Odrzucona' },
];

type StatusMenuProps = {
  initValue: MenuPickerProps<string | null>['value'];
  onStatusChange: MenuPickerProps<string | null>['onChange'];
  showAll?: true;
};

const AllStatuses: MenuPickerOption<string | null> = { value: null, label: 'Wszystkie statusy' };

function StatusMenu({ initValue, onStatusChange, showAll }: StatusMenuProps) {
  const options: MenuPickerOption<string | null>[] = useMemo<MenuPickerOption<string | null>[]>(() => {
    return [...(showAll ? [AllStatuses] : []), ...StatusOptions];
  }, [showAll]);

  return (
    <MenuPicker<string | null>
      value={initValue}
      options={options}
      defaultColor={'#303030'}
      defaultBackground={'#fff'}
      onChange={onStatusChange}
    >
      <ColoredIconBox value={'Status'} background={'#fff'} color={'#969696'} />
    </MenuPicker>
  );
}

export default StatusMenu;
