import { EmployeeModule } from '@Employee/Employee';
import logo from 'assets/images/logo-light.png';
import BaseInput from 'components/Form/BaseInput';
import { put } from 'helpers/Axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Alert, Col, Container, Row } from 'reactstrap';

const ResetPassword = props => {
  document.title = 'Przypomnienie hasła | CTM';

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const submitNewPassword = () => {
    setError('');
    setSuccess('');

    put(EmployeeModule.configuration.api.item.putResetPassword({ token: props.match.params.token }), { password, password2 })
      .then(() => {
        setSuccess('Hasło zostało zmienione. Możesz się teraz zalogować');
      })
      .catch(err => {
        if (err.hasOwnProperty('response')) {
          setError(err.response.data['hydra:description'] ?? err.response.data.message ?? err.response.data.detail);
        } else {
          setError('Wystąpił problem z ustawieniem nowego hasła, skontaktuj się z administratorem.');
        }
      });
  };

  return (
    <Container fluid className="p-0">
      <Row className="p-0 m-0">
        <Col xl={9} className="p-0">
          <div className="auth-full-bg pt-lg-5 p-4">
            <div className="w-100">
              <div className="bg-overlay"></div>
            </div>
          </div>
        </Col>
        <Col xl={3}>
          <div className="auth-full-page-content p-md-5 p-4">
            <div className="w-100">
              <div className="d-flex flex-column h-100">
                <div className="mb-4 mb-md-5">
                  <a href="/" className="d-block auth-logo text-center">
                    <img src={logo} style={{ maxWidth: '100%' }} />
                  </a>
                </div>
                <div className="my-auto text-center" style={{ maxWidth: 360, margin: '0 auto' }}>
                  <div>
                    <h5 className="text-primary">Ustaw nowe hasło</h5>
                    <p className="text-muted">Podaj nowe hasło do swojego konta.</p>
                  </div>
                  {error !== '' && <Alert color="danger">{error}</Alert>}
                  {success !== '' && <Alert color="success">{success}</Alert>}
                  <div className="mt-1">
                    <BaseInput
                      size={{ md: 12 }}
                      className={''}
                      value={password}
                      type={'password'}
                      name="password"
                      onChange={value => setPassword(value)}
                      inputProps={{
                        placeholder: 'Podaj nowe hasło',
                      }}
                    />
                  </div>
                  <div className="mt-1">
                    <BaseInput
                      size={{ md: 12 }}
                      className={''}
                      value={password2}
                      type={'password'}
                      name="password2"
                      onChange={value => setPassword2(value)}
                      inputProps={{
                        placeholder: 'Powtórz hasło',
                      }}
                    />
                  </div>
                  <div className="text-center">
                    <button className="btn btn-primary btn-block" onClick={submitNewPassword}>
                      Zapisz nowe hasło
                    </button>
                  </div>
                  <div className="text-center mt-2">
                    <Link to="/login" className="text-muted">
                      <i className="mdi mdi-lock me-1" />
                      Zaloguj się
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default withRouter(ResetPassword);

ResetPassword.propTypes = {
  history: PropTypes.object,
};
