import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import { AccountancyValueAddedTaxModule } from 'Modules/CTMModules';
import { useQuery } from 'react-query';

export default function useTaxes() {
  const query = useQuery<Partial<ValueAddedTax>[]>(
    [AccountancyValueAddedTaxModule.configuration.urlPrefix],
    () => AccountancyValueAddedTaxModule.api.getAll({ params: { pagination: false } }).then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );

  return {
    ...query,
    data: query.data || [],
  };
}
