import { Invoice } from '@Accountancy/Invoice/Invoice';
import PrintItemButton from '@Components/DataGrid/Button/PrintItemButton';
import { ContextActionsProps } from '@Core/Types/CTMModule';

function InvoiceContextActions({ record }: ContextActionsProps<Invoice>) {
  if (!record.id) {
    return <></>;
  }

  return (
    <>
      <PrintItemButton
        id={record.number}
        name={'Faktura'}
        downloadUrl={record['@id']}
        description={'Pobierz plik Faktury.'}
        label={false}
      />
    </>
  );
}

export default InvoiceContextActions;
