import Loader from '@Components/Theme/Common/Loader';
import { Button } from '@mui/material';
import { usePropertyValues } from 'Modules/Core/Hooks/usePropertyValues';
import ListPicker from 'components/Form/ListPicker';

export const MultipleSalesPicker = props => {
  return <SalesPicker {...props} multiple={true} />;
};

const SalesPicker = ({ field, value, onChange, readonly, errorKey, customProps = {}, multiple = false }) => {
  const { data: statuses, isLoading } = usePropertyValues('manufacture-products', 'sale');
  const currentValue = (value || field.defaultValue) ?? (multiple ? ['ON_SALE'] : '');
  if (isLoading) {
    return <Loader />;
  }

  return (
    <ListPicker
      name={field.id}
      label={field.name}
      tabIndex={field._tabindex}
      value={currentValue}
      options={statuses}
      multiple={multiple}
      optionLabel={item => (
        <Button
          variant={'contained'}
          startIcon={item.icon ? <i className={`mdi mdi-${item.icon}`} style={{ lineHeight: 'initial', paddingLeft: 4 }}></i> : undefined}
          style={{
            width: '100%',
            padding: '3px',
            color: '#fff',
            margin: '0 2px',
            backgroundColor: item?.color,
          }}
        >
          {item?.label}
        </Button>
      )}
      optionTrackBy="value"
      disabled={readonly}
      disableGroupMargin={true}
      labelBadge={field.position}
      errorKey={'status'}
      onChange={val => onChange(val, field.id)}
    />
  );
};

export default SalesPicker;
