import StatusView from '../Core/Components/StatusView';
import PrintItemButton from '@Components/DataGrid/Button/PrintItemButton';
import { SelectModuleRecordColumnFilter } from '@Components/DataGrid/Filter';
import FilterModuleProperty from '@Components/DataGrid/Filter/FilterModuleProperty';
import Contractor, { Contractor as ContractorModel } from '@Contractor/Contractor';
import StoreValues from '@Ecommerce/Components/StoreValues';
import ProductionOrderQuantity from '@Manufacture/Components/ProductionOrder/ProductionOrderQuantity';
import CTMModule, { CTMListColumn, CTMRecord } from 'Modules/Core/Types/CTMModule';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';

export type ProcurementOrderNotification = CTMRecord & {
  labelerId?: string;
  contractor: string | ContractorModel | undefined;
  rows: any[];
};

const columns: CTMListColumn<ProcurementOrderNotification>[] = [
  {
    id: 'labelerId',
    filterable: true,
    sortable: true,
    Header: 'Numer',
    noBreak: true,
    accessor: ({ labelerId }) => 'ZDD ' + labelerId,
  },
  {
    id: 'contractor.id',
    filterable: true,
    sortable: true,
    Header: 'Dostawca',
    accessor: 'contractor.relationFieldLabel',
    Filter: SelectModuleRecordColumnFilter,
    filterModule: Contractor,
    minWidth: 160,
  },
  {
    id: 'createdAt',
    Header: 'Data utworzenia',
    accessor: ({ createdAt }) => (createdAt ? dayjs(createdAt).locale('pl').format('LLL') : null),
    filterable: true,
    sortable: true,
    minWidth: 160,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => <StatusView moduleName={'warehouse-procurement-orders'} status={status} />,
    sortable: true,
    filterable: true,
    Filter: FilterModuleProperty,
    filterModuleName: 'warehouse-procurement-orders',
    filterPropertyName: 'status',
  },
  {
    id: 'rows.product.symbol',
    filterable: true,
    sortable: true,
    Header: 'Symbol',
    noBreak: true,
    accessor: 'product.symbol',
    filterSearchField: 'rows.product.symbol',
  },
  {
    id: 'rows.product.name',
    filterable: true,
    sortable: true,
    Header: 'Produkt',
    noBreak: true,
    accessor: 'product.name',
    filterSearchField: 'rows.product.name',
  },
  {
    id: 'rows.product.store',
    filterable: false,
    sortable: false,
    Header: 'Stany',
    noBreak: true,
    accessor: '',
    Cell: ({ row }) => <StoreValues key={row.original.id} product={row.original.product} renderFetch={true} />,
  },
  {
    id: 'rows.quantity',
    filterable: true,
    sortable: true,
    Header: 'Ilość',
    Cell: ({ row }) => (
      <ProductionOrderQuantity
        key={row.original.rowId}
        id={row.original.rowId}
        quantity={row.original.quantity}
        url={'/manufacture/production-order-rows'}
      />
    ),
    noBreak: true,
    accessor: 'quantity',
  },
  {
    id: 'deadlineAt',
    Header: 'Planowana data dostawy',
    accessor: ({ deadlineAt }) => (deadlineAt ? dayjs(deadlineAt).locale('pl').format('LL') : null),
    filterable: true,
    sortable: true,
    minWidth: 160,
  },
];

const module: CTMModule<ProcurementOrderNotification> = {
  id: 'bac5b26c-a70a-4dde-8916-f2a257f6045f',
  dataClass: 'CTM\\Warehouse\\Entity\\ProcurementOrder\\ProcurementOrder',
  urlPrefix: 'warehouse-procurement-orders-notifications',
  name: 'Awizacja dostaw',
  role: 'WAREHOUSE_PROCUREMENT_NOTIFICATION',
  api: {
    item: {
      get: ({ id }) => `/warehouse/procurement-orders/notifications/${id}`,
      put: ({ id }) => `/warehouse/procurement-orders/notifications/${id}`,
      delete: ({ id }) => `/warehouse/procurement-orders/notifications/${id}`,
    },
    collection: {
      get: `/warehouse/procurement-orders/notifications`,
      post: `/warehouse/procurement-orders/notifications/`,
    },
  },
  recordLabel: record => record.labelerId,
  list: {
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'createdAt', desc: false }],
    storeFilters: true,
    board: true,
    boardCardOverride: ({ item, module }) => {
      return (
        <CardBody className="borad-width">
          <div>
            <h5 className="font-size-15">
              <Link to={module.showUrl(item.id)} className="text-dark">
                {item?.labelerId}
              </Link>
            </h5>
          </div>
          <div className="text-end">
            <p className="mb-0 text-muted">{dayjs(item?.createdAt).locale('pl').format('LLL')}</p>
          </div>
        </CardBody>
      );
    },
    inlineComponentPrependActions: (row, listRef) => (
      <>
        <PrintItemButton
          id={(row.labelerId ?? '').toString()}
          name={'Zamówienie do dostawcy'}
          downloadUrl={`/warehouse/procurement-orders/${row.id}`}
          description={'Pobierz plik PDF z zamówieniem.'}
          parameters={{}}
          label={false}
          languageSelect={false}
        />
      </>
    ),
  },
};

export default module;
