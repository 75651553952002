import { createModuleColumnConfig, setValuesToFormRecord } from '../../../helpers/ModuleUtils';
import InvoiceExpandedRow from './Components/List/InvoiceExpandedRow';
import DepartmentModule, { Department } from '@Accountancy/Department/Department';
import InvoiceContextHeader from '@Accountancy/Invoice/Components/ContextHeader/InvoiceContextHeader';
import InvoiceContextActions from '@Accountancy/Invoice/Components/Form/InvoiceContextActions';
import LanguageField from '@Accountancy/Invoice/Components/Form/LanguageField';
import DataGridHeader from '@Accountancy/Invoice/Components/List/DataGridHeader';
import PaymentDeadlineFilter from '@Accountancy/Invoice/Components/List/PaymentDeadlineFilter';
import StatusFilter from '@Accountancy/Invoice/Components/List/StatusFilter';
import TypeFilter from '@Accountancy/Invoice/Components/List/TypeFilter';
import InvoiceDefaultsConfiguration from '@Accountancy/Invoice/Configuration/InvoiceDefaultsConfiguration';
import InvoiceDepartmentsConfiguration from '@Accountancy/Invoice/Configuration/InvoiceDepartmentsConfiguration';
import InvoiceNumerationConfiguration from '@Accountancy/Invoice/Configuration/InvoiceNumerationConfiguration';
import { InvoiceStatus, statusLabel } from '@Accountancy/Invoice/InvoiceStatuses';
import { InvoiceType, InvoiceTypes, typeLabel } from '@Accountancy/Invoice/InvoiceTypes';
import InvoiceDefaultSection from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import PrintItemButton from '@Components/DataGrid/Button/PrintItemButton';
import MoneyView from '@Components/View/MoneyView';
import Contractor, { Contractor as ContractorModel } from '@Contractor/Contractor';
import { ValueAddedTax } from '@Core/Accountancy/ValueAddedTax';
import useFetchModuleConfig from '@Core/Hooks/useFetchModuleConfig';
import Address from '@Core/Types/Address';
import { Field } from '@CustomFields/Field';
import { PaymentTypeModel } from '@Ecommerce/PaymentType';
import { EmployeeWithImageURL } from '@Employee/Employee/Types/Employee';
import { Product } from '@Manufacture/Product';
import { Unit } from '@Manufacture/Unit';
import { ArrowLeftOutlined, ArrowRightOutlined, AttachMoneyOutlined } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import CTMModule, { CTMApiMixed, CTMListColumn, CTMStrictRecord } from 'Modules/Core/Types/CTMModule';
import { post } from 'helpers/Axios';
import moment from 'moment/moment';
import { LocalStorageKey } from 'store/Accountancy/Reducer';

export type GTU =
  | 'GTU_01'
  | 'GTU_02'
  | 'GTU_03'
  | 'GTU_04'
  | 'GTU_05'
  | 'GTU_06'
  | 'GTU_07'
  | 'GTU_08'
  | 'GTU_09'
  | 'GTU_10'
  | 'GTU_11'
  | 'GTU_12'
  | 'GTU_13';

export type InvoiceConfiguration = {
  InvoiceDefaults: {
    innerContractor: null | string | ContractorModel;
    issueAddress: Address;
  };
  InvoiceNumeration: {
    types: Record<InvoiceType, string>;
    numberLength: number;
  };
};

export type InvoiceContractor = {
  name: string;
  taxNumberPrefix: string | null;
  taxNumber: string | null;
  backAccount: string | null;
  backName: string | null;
  street: string | null;
  house: string | null;
  flat: string | null;
  post: string | null;
  city: string | null;
  district: string | null;
  province: string | null;
  country: string | null;
  email: string | null;
  fax: string | null;
  phone: string | null;
  www: string | null;
  additionalInformation: string | null;
};

export type InvoicePosition = {
  id: string;
  seq: number;
  product: Product | null;
  name: string;
  additionalInfo: string | null;
  description: string | null;
  code: string | null;
  GTU: GTU | null;
  discount: number;
  quantity: number;
  unit: Unit | null;
  priceUnitNet: number;
  priceUnitGross: number;
  priceTotalNet: number;
  priceTotalGross: number;
  priceUnitNetHomeCurrency: number;
  priceUnitGrossHomeCurrency: number;
  priceTotalNetHomeCurrency: number;
  priceTotalGrossHomeCurrency: number;
  tax: ValueAddedTax | null;
};
export type InvoicePayment = {
  id: string;
  paymentType: string | null;
  transaction: string | null;
  description: string | null;
  priceTotalGross: number;
  priceTotalGrossHomeCurrency: number;
  receivedDate: string | null;
};

export type Invoice = CTMStrictRecord & {
  id: string;
  type: InvoiceType;
  paymentType: null | string | PaymentTypeModel;
  number: string;
  externalNumber: null | string;
  income: boolean;
  oss: boolean;
  splitPayment: boolean;
  status: InvoiceStatus;
  amountPaidGross: number;
  amountPaidNet: number;
  amountPaidGrossHomeCurrency: number;
  amountPaidNetHomeCurrency: number;
  totalPriceNet: number;
  totalPriceVat: number;
  totalPriceVatHomeCurrency: number;
  totalPriceGross: number;
  totalPriceGrossHomeCurrency: number;
  totalPriceNetHomeCurrency: number;
  currency: string;
  exchangeCurrency: string;
  exchangeRate: number;
  exchangeInfoDate: string;
  lang: string;
  secondLang: string;
  description: null | string;
  ownDescription: null | string;
  gtuCodes: string[];
  parent: null | Partial<Invoice>;
  createdFrom: null | Partial<Invoice>;
  correction: null | Partial<Invoice>;
  issueDate: string;
  sellDate: string;
  paymentDeadline: string;
  daysAfterPaymentDeadline: null | number;
  paymentDate: string;
  issueAddress: Address;
  seller: null | ContractorModel;
  buyer: null | ContractorModel;
  recipient: null | ContractorModel;
  sellerRaw: InvoiceContractor;
  buyerRaw: InvoiceContractor;
  recipientRaw: InvoiceContractor;
  positions: InvoicePosition[];
  payments: InvoicePayment[];
  department: null | string | Department;
};

export const sumPositions = (positions: InvoicePosition[], field: keyof InvoicePosition): number => {
  const sum = positions.reduce((prev, curr) => {
    const partValue = curr[field];
    if (typeof partValue === 'number') {
      return prev + partValue;
    }
    return prev;
  }, 0);
  return parseFloat(sum.toFixed(2));
};
export const sumPayments = (payments: InvoicePayment[], field: keyof InvoicePayment): number => {
  const sum = payments.reduce((prev, curr) => {
    const partValue = curr[field];
    if (typeof partValue === 'number') {
      return prev + partValue;
    }
    return prev;
  }, 0);
  return parseFloat(sum.toFixed(2));
};

const columns: CTMListColumn<Invoice>[] = [
  {
    id: 'department.id',
    filterable: true,
    sortable: false,
    forceHidden: column => true,
    Header: 'Typ',
    minWidth: 160,
    flex: 1,
    ...createModuleColumnConfig('department', DepartmentModule),
  },
  {
    id: 'number',
    Header: 'Numer',
    accessor: ({ number, income }) => {
      const color = income ? 'success' : 'error';
      const IconComponent = income ? ArrowRightOutlined : ArrowLeftOutlined;

      return (
        <Box display="flex">
          <IconComponent color={color} />
          <AttachMoneyOutlined color={color} />
          <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
            {number}
          </Typography>
        </Box>
      );
    },
    filterable: true,
    sortable: true,
  },
  {
    id: 'externalNumber',
    Header: 'Numer zew. system',
    accessor: 'externalNumber',
    filterable: true,
    sortable: true,
  },
  {
    id: 'type',
    Header: 'Typ',
    accessor: ({ type }) => typeLabel(type),
    filterable: true,
    Filter: TypeFilter,
    sortable: false,
    noBreak: true,
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: ({ status }) => statusLabel(status),
    filterable: true,
    Filter: StatusFilter,
    sortable: false,
    noBreak: true,
  },
  {
    id: 'seller.id',
    Header: 'Sprzedawca',
    filterable: true,
    sortable: false,
    minWidth: 160,
    ...createModuleColumnConfig('seller', Contractor),
  },
  {
    id: 'buyer.id',
    Header: 'Kupujący',
    filterable: true,
    sortable: true,
    minWidth: 160,
    ...createModuleColumnConfig('buyer', Contractor),
  },
  {
    id: 'totalPriceNetHomeCurrency',
    Header: 'Netto',
    accessor: ({ totalPriceNetHomeCurrency, totalPriceNet, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceNet}
        homeCurrencyValue={totalPriceNetHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
  },
  {
    id: 'totalPriceVatHomeCurrency',
    Header: 'VAT',
    accessor: ({ totalPriceVatHomeCurrency, totalPriceVat, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceVat}
        homeCurrencyValue={totalPriceVatHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
  },
  {
    id: 'totalPriceGrossHomeCurrency',
    Header: 'Brutto',
    accessor: ({ totalPriceGrossHomeCurrency, totalPriceGross, currency, exchangeRate, issueDate, exchangeInfoDate }) => (
      <MoneyView
        value={totalPriceGross}
        homeCurrencyValue={totalPriceGrossHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
  },
  {
    id: 'amountPaidGrossHomeCurrency',
    Header: 'Opłacona kwota',
    accessor: ({ amountPaidGrossHomeCurrency, amountPaidGross, currency, exchangeInfoDate, issueDate, exchangeRate }) => (
      <MoneyView
        value={amountPaidGross}
        homeCurrencyValue={amountPaidGrossHomeCurrency}
        currency={currency}
        exchangeDate={exchangeInfoDate ?? issueDate}
        exchangeRate={exchangeRate}
      />
    ),
    filterable: false,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'issueDate',
    Header: 'Data wystawienia',
    accessor: ({ issueDate }) => moment(issueDate).format('lll'),
    filterable: true,
    sortable: true,
    noBreak: true,
  },
  {
    id: 'daysAfterPaymentDeadline',
    Header: 'Termin płatności',
    accessor: ({ daysAfterPaymentDeadline, paymentDeadline }) =>
      null !== daysAfterPaymentDeadline ? moment(paymentDeadline).format('lll') : null,
    filterable: true,
    Filter: PaymentDeadlineFilter,
    filterPropertyName: 'paymentDeadline',
    orderPropertyName: 'paymentDeadline',
    sortable: true,
    noBreak: true,
  },
];

type CreateFromClientOrderInput = {
  clientOrder: string;
  invoiceType: InvoiceType;
  department: null;
};

type CustomActions = {
  createFromClientOrder: (data: CreateFromClientOrderInput) => Promise<Invoice>;
};

export type InvoiceModule = CTMModule<Invoice, CTMApiMixed, CTMApiMixed, CustomActions>;

const module: InvoiceModule = {
  id: '76c6b67d-634b-4fa3-9ee1-24b7b30cadcd',
  dataClass: 'CTM\\Accountancy\\Entity\\Invoice',
  urlPrefix: 'accountancy-invoice',
  name: 'Faktury',
  role: 'ACCOUNTANCY_INVOICE',
  api: {
    item: {
      get: ({ id }) => `/accountancy/invoices/${id}`,
      put: ({ id }) => `/accountancy/invoices/${id}`,
      delete: ({ id }) => `/accountancy/invoices/${id}`,
    },
    collection: {
      get: `/accountancy/invoices`,
      post: `/accountancy/invoices`,
    },
    custom: {
      createFromClientOrder: data => post<Invoice>('/accountancy/invoices/from-client-order', data),
    },
  },
  recordLabel: ({ number, income, type }) =>
    `${InvoiceTypes.find(el => el.type === type)?.label} (${income ? 'Przychód' : 'Koszt'}) ${number ? ` - ${number}` : ''}`,
  form: {
    disableCreateNewRecord: true,
    sectionComponents: {
      ['7abe5de2-bdc3-4423-abd1-f5eda3897f03']: InvoiceDefaultSection,
    },
    fieldComponents: {
      lang: LanguageField,
      secondLang: LanguageField,
    },
    defaultRecord: (allFields: Field[], currentUser: EmployeeWithImageURL, queryParams: any, config: InvoiceConfiguration) => {
      const income = queryParams?.income === 'true';
      const rawPrefix = income ? 'sellerRaw' : 'buyerRaw';
      return setValuesToFormRecord({ '@formValues': {} }, allFields, {
        income,
        type: queryParams?.type ?? InvoiceTypes[0].type,
        [income ? 'seller' : 'buyer']: config.InvoiceDefaults.innerContractor,
        ...(typeof config.InvoiceDefaults.innerContractor === 'object'
          ? {
              [`${rawPrefix}.name`]: config.InvoiceDefaults.innerContractor?.name,
              [`${rawPrefix}.taxNumberPrefix`]: config.InvoiceDefaults.innerContractor?.tin.prefix,
              [`${rawPrefix}.taxNumber`]: config.InvoiceDefaults.innerContractor?.tin.number,
              [`${rawPrefix}.post`]: config.InvoiceDefaults.innerContractor?.address.post,
              [`${rawPrefix}.city`]: config.InvoiceDefaults.innerContractor?.address.city,
              [`${rawPrefix}.province`]: config.InvoiceDefaults.innerContractor?.address.province,
              [`${rawPrefix}.district`]: config.InvoiceDefaults.innerContractor?.address.district,
              [`${rawPrefix}.street`]: config.InvoiceDefaults.innerContractor?.address.street,
              [`${rawPrefix}.house`]: config.InvoiceDefaults.innerContractor?.address.house,
              [`${rawPrefix}.flat`]: config.InvoiceDefaults.innerContractor?.address.flat,
              [`${rawPrefix}.country`]: config.InvoiceDefaults.innerContractor?.address.country,
            }
          : {}),
        ['issueAddress.post']: config.InvoiceDefaults.issueAddress.post,
        ['issueAddress.city']: config.InvoiceDefaults.issueAddress.city,
        ['issueAddress.province']: config.InvoiceDefaults.issueAddress.province,
        ['issueAddress.district']: config.InvoiceDefaults.issueAddress.district,
        ['issueAddress.street']: config.InvoiceDefaults.issueAddress.street,
        ['issueAddress.house']: config.InvoiceDefaults.issueAddress.house,
        ['issueAddress.flat']: config.InvoiceDefaults.issueAddress.flat,
        ['issueAddress.country']: config.InvoiceDefaults.issueAddress.country,
      });
    },
    forceReadonlyField: field => {
      return field.propertyPath === 'number';
    },
    prepareFetchedRecord: (record, allFields) => {
      return setValuesToFormRecord(record, allFields, {
        positions: record.positions
          ?.map(el => ({
            ...el,
            priceUnitNet: parseFloat((el.priceUnitNet / 100).toFixed(2)),
            priceUnitGross: parseFloat((el.priceUnitGross / 100).toFixed(2)),
            priceTotalNet: parseFloat((el.priceTotalNet / 100).toFixed(2)),
            priceTotalGross: parseFloat((el.priceTotalGross / 100).toFixed(2)),
          }))
          .sort((a, b) => a.seq - b.seq),
        payments: record.payments?.map(el => ({
          ...el,
          priceTotalGross: parseFloat((el.priceTotalGross / 100).toFixed(2)),
        })),
      });
    },
    prepareRecordToSave: (record, allFields) => {
      const isUpdate = record.hasOwnProperty('@id');

      return {
        ...record,
        seller: record.seller?.['@id'] ?? record.seller,
        buyer: record.buyer?.['@id'] ?? record.buyer,
        paymentType: record.paymentType?.['@id'] ?? record.paymentType,
        department: isUpdate
          ? undefined
          : localStorage.getItem(LocalStorageKey) === 'undefined'
          ? null
          : localStorage.getItem(LocalStorageKey),
        recipient: record.recipient?.['@id'] ?? record.recipient,
        positions: record.positions?.map(el => ({
          ...el,
          unit: el.unit?.['@id'] ?? el.unit,
          tax: el.tax?.['@id'] ?? el.tax,
          product: el.product?.['@id'] ?? el.product,
          priceUnitNet: Math.round(el.priceUnitNet * 100) || 0,
          priceUnitGross: Math.round(el.priceUnitGross * 100) || 0,
          priceTotalNet: Math.round(el.priceTotalNet * 100) || 0,
          priceTotalGross: Math.round(el.priceTotalGross * 100) || 0,
        })),
        payments: record.payments?.map(el => ({
          ...el,
          priceTotalGross: Math.round(el.priceTotalGross * 100) || 0,
        })),
      };
    },
    contextActions: InvoiceContextActions,
  },
  list: {
    headerOverride: DataGridHeader,
    columns: columns,
    defaultFilters: [],
    defaultOrderBy: [{ id: 'issueDate', desc: true }],
    resolveQueryKey: rootstate => `accountancy-invoices-${rootstate.Accountancy.departmentIRI}`,
    canRenderSubRow: () => true,
    renderRowSubComponent: ({ row: { original } }, modalListActions) => <InvoiceExpandedRow invoice={original} />,
    rowStylesCallback: ({ original: { daysAfterPaymentDeadline } }) => {
      if (daysAfterPaymentDeadline !== null) {
        switch (true) {
          case daysAfterPaymentDeadline >= 31:
            return { backgroundColor: '#b71c1c', color: '#fff' };
          case daysAfterPaymentDeadline >= 14:
            return { backgroundColor: '#ef5350' };
          case daysAfterPaymentDeadline >= 0:
            return { backgroundColor: '#ffcdd2' };
          default:
            return {};
        }
      }

      return {};
    },
    inlineComponentPrependActions: (row, listRef) => (
      <>
        <PrintItemButton id={row.number} name={'Faktura'} downloadUrl={row['@id']} description={'Pobierz plik Faktury.'} label={false} />
      </>
    ),
  },
  configuration: {
    InvoiceDefaults: {
      name: 'Ogólne',
      component: InvoiceDefaultsConfiguration,
    },
    InvoiceNumeration: {
      name: 'Numeracja',
      component: InvoiceNumerationConfiguration,
    },
    Departments: {
      name: 'Działy',
      component: InvoiceDepartmentsConfiguration,
    },
  },
  contextHeader: InvoiceContextHeader,
};

export const useInvoiceConfiguration = () => useFetchModuleConfig<InvoiceConfiguration>(module.id);

export default module;
