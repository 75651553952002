import LanguageMenu from './LanguageMenu';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'store';
import { rootSetLanguage } from 'store/Root/Root';

const IconMap = {
  en: 'gb',
};

const LanguagePicker = ({ isReadonly, backgroundColor = 'transparent', border = '1px solid rgb(206, 212, 218)' }) => {
  const selectedLanguage = useAppSelector(state => state.Root.language) as string;
  const dispatch = useDispatch();
  const handleLanguagePick = useCallback((language: string) => {
    dispatch(rootSetLanguage(language));
    setAnchorEl(null);
  }, []);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div>
      <div
        onClick={handleClick}
        style={{
          width: 35,
          height: '20px',
          border: border,
          backgroundColor: backgroundColor,
          position: 'relative',
          borderRadius: '4px',
          marginRight: 5,
          overflow: 'hidden',
          cursor: 'pointer',
        }}
        title={'Zmień język pola'}
      >
        <div
          className={`fi fi-${IconMap?.[selectedLanguage] ?? selectedLanguage}`}
          style={{
            width: '90%',
            height: 'calc(100% - 2px)',
            position: 'absolute',
            left: '5%',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          &nbsp;
        </div>
      </div>
      <LanguageMenu onChange={handleLanguagePick} onClose={() => setAnchorEl(null)} anchorEl={anchorEl} />
    </div>
  );
};

export default LanguagePicker;
