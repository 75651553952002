import { Invoice } from '@Accountancy/Invoice/Invoice';
import PositionsGroup from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoicePositionsGroup';
import useInvoiceFields from '@Accountancy/Invoice/Sections/useInvoiceFields';
import { Box } from '@mui/material';
import { useModule } from 'context/ModuleContext';

function InvoiceExpandedRow({ invoice }) {
  const module = useModule();
  const indexedNamedFields = useInvoiceFields(Object.values(invoice['@form']));
  const invoiceRecord: Invoice =
    module.module.configuration.form?.prepareFetchedRecord?.(JSON.parse(JSON.stringify(invoice)), Object.values(indexedNamedFields)) ??
    invoice;

  return (
    <Box sx={{ padding: 2, paddingTop: 0 }}>
      <PositionsGroup
        fields={indexedNamedFields}
        values={invoiceRecord['@formValues']}
        onUpdate={console.log}
        readonly={true}
        hideLabel={true}
      />
    </Box>
  );
}

export default InvoiceExpandedRow;
