import { CustomFieldComponentType } from '@Components/CustomFields/CustomField';
import ListPicker from '@Components/Form/ListPicker';
import Loader from '@Components/Theme/Common/Loader';
import { Language as LanguageModel } from '@Core/Language';
import { Priority } from '@Core/Types/Priority';
import { LanguageModule } from '@Modules/CTMModules';
import { useQuery } from 'react-query';

const LanguageField: CustomFieldComponentType<Priority | null | number> = ({ field, value, onChange, readonly }) => {
  const { data: languages, isLoading } = useQuery<LanguageModel[]>(
    ['allLanguages'],
    () => LanguageModule.api.getAll({ params: { pagination: false } }).then(res => res['hydra:member']),
    {
      cacheTime: 10 * 60 * 1000,
      staleTime: 10 * 60 * 1000,
      refetchOnWindowFocus: true,
      refetchInterval: false,
    },
  );
  if (isLoading || !languages) {
    return <Loader />;
  }
  return (
    <ListPicker<LanguageModel>
      name={field.name}
      label={field.name}
      value={value}
      options={languages ?? []}
      labelerId={field.labelerId}
      optionLabel={item => item.isoCode}
      optionTrackBy="isoCode"
      disabled={readonly}
      errorKey={field.id}
      onChange={val => onChange?.(val, field.id)}
    />
  );
};

export default LanguageField;
