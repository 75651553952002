import { InvoiceGroupProps } from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceDefaultSection';
import InvoiceField from '@Accountancy/Invoice/Sections/InvoiceDefaultSection/InvoiceField';
import { Col, Container } from '@Components/Theme/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Paper, Typography } from '@mui/material';
import { FC, useState } from 'react';

const InvoiceLocationFieldsGroup: FC<InvoiceGroupProps> = props => {
  const [expanded, setExpanded] = useState<boolean>(false);

  return (
    <Paper>
      <Accordion expanded={expanded} sx={{ boxShadow: '0', borderRadius: 0 }} onChange={(_, newState) => setExpanded(newState)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
          <Container>
            <Col xs={12}>
              <Typography variant="subtitle1" align="center">
                Miejsce wystawienia
              </Typography>
              {!expanded && (
                <Typography variant="subtitle2" align="center">
                  {props.values[props.fields['issueAddress.city'].id]} {props.values[props.fields['issueAddress.street'].id]}{' '}
                  {props.values[props.fields['issueAddress.house'].id]}
                  {props.values[props.fields['issueAddress.flat'].id] && `/${props.values[props.fields['issueAddress.flat'].id]}`}
                </Typography>
              )}
            </Col>
          </Container>
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <Col xs={12}>
              <Container paddingLeft={2}>
                <InvoiceField {...props} size={3} fieldKey={'issueAddress.post'} fieldOverride={{ name: 'Kod pocztowy' }} />
                <InvoiceField {...props} size={9} fieldKey={'issueAddress.city'} fieldOverride={{ name: 'Miasto' }} />
                <InvoiceField {...props} size={4} fieldKey={'issueAddress.country'} fieldOverride={{ name: 'Kraj' }} />
                <InvoiceField {...props} size={4} fieldKey={'issueAddress.province'} fieldOverride={{ name: 'Województwo' }} />
                <InvoiceField {...props} size={4} fieldKey={'issueAddress.district'} fieldOverride={{ name: 'Gmina' }} />
                <InvoiceField {...props} size={6} fieldKey={'issueAddress.street'} fieldOverride={{ name: 'Ulica' }} />
                <InvoiceField {...props} size={3} fieldKey={'issueAddress.house'} fieldOverride={{ name: 'Budynek' }} />
                <InvoiceField {...props} size={3} fieldKey={'issueAddress.flat'} fieldOverride={{ name: 'Lokal' }} />
              </Container>
            </Col>
          </Container>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};

export default InvoiceLocationFieldsGroup;
