import StatusMenu from '../Common/StatusMenu';
import { ClearButton } from '@Components/DataGrid/Filter/ClearButton';
import { useEffect, useState } from 'react';

function StatusFilter({ column }) {
  const [val, setVal] = useState(column.filterValue ?? null);

  useEffect(() => {
    column.setFilter(val);
  }, [val]);

  return (
    <div className={'module-filter-wrapper'}>
      <StatusMenu initValue={val} onStatusChange={newStatus => setVal(newStatus.value)} showAll />
      <ClearButton clear={() => setVal(null)} isFilter={val !== null} />
    </div>
  );
}

export default StatusFilter;
