import { Tooltip } from '@mui/material';

interface ComponentProps {
  available: boolean;
}

const ProductStoreActive = ({ available }: ComponentProps) => {
  return (
    <Tooltip arrow title={available ? 'Produkt dostępny na sklepie' : 'Produkt niedostępny na sklepie'}>
      <div className={`store-active__wrapper ${available ? 'store-active__wrapper--active' : ''}`}>
        {available ? <i className="mdi mdi-store" /> : <i className="mdi mdi-store-remove" />}
      </div>
    </Tooltip>
  );
};

export default ProductStoreActive;
