import axios from 'axios';
import dayjs from 'dayjs';

class CurrencyExchangeRate {
  private _from: string;
  private _to: string;
  private _date: string;
  private _mid: number;

  constructor(from, to, date, mid) {
    this._from = from;
    this._to = to;
    this._date = date;
    this._mid = mid;
  }

  public getTo() {
    return this._to;
  }

  public getMid() {
    return this._mid;
  }

  public getDate() {
    return this._date;
  }

  public exchangeBuy(value) {
    return parseFloat((this._mid * value).toFixed(2));
  }

  public exchangeSell(value) {
    return parseFloat((value / this._mid).toFixed(2));
  }
}

class CurrencyExchange {
  static cache: { [key: string]: CurrencyExchangeRate } = {};
  static inGenerate: { [key: string]: string } = {};

  static async getCurrencyExchangeRate(from: string, to: string, date) {
    if (from !== 'PLN') {
      throw new Error('Only PLN is supported as base currency');
    }
    if (to === 'PLN') {
      return new CurrencyExchangeRate(from, to, date, 1);
    }
    const cacheKey = CurrencyExchange.getCacheKey(from, to, date);
    if (!to) {
      return new CurrencyExchangeRate(from, to, date, 1);
    }
    if (!CurrencyExchange.cache.hasOwnProperty(cacheKey)) {
      if (CurrencyExchange.inGenerate.hasOwnProperty(cacheKey)) {
        while (CurrencyExchange.inGenerate.hasOwnProperty(cacheKey)) {
          await new Promise(resolve => setTimeout(resolve, 100));
        }
        return CurrencyExchange.cache[cacheKey];
      }
      CurrencyExchange.inGenerate[cacheKey] = cacheKey;
      let tmpDate: string = (dayjs(date).isValid() ? dayjs(date) : dayjs()).format('YYYY-MM-DD');
      let i = 0;

      while (i <= 10) {
        i++;
        await new Promise<any>((resolve, reject) => {
          axios
            .get(`https://api.nbp.pl/api/exchangerates/rates/a/${to}/${tmpDate}/?format=json`)
            .then(response => {
              CurrencyExchange.cache[cacheKey] = new CurrencyExchangeRate(from, to, tmpDate, response.data.rates[0].mid);
              delete CurrencyExchange.inGenerate[cacheKey];
              i = 99;
              resolve({ success: true });
              return CurrencyExchange.cache[cacheKey];
            })
            .catch(error => {
              tmpDate = dayjs(tmpDate).subtract(1, 'days').format('YYYY-MM-DD');
              resolve({ success: false });
            });
        });
      }
      delete CurrencyExchange.inGenerate[cacheKey];

      if (!CurrencyExchange.cache[cacheKey]) {
        CurrencyExchange.cache[cacheKey] = new CurrencyExchangeRate(from, to, dayjs(date).format('YYYY-MM-DD'), 1);
      }
    }
    return CurrencyExchange.cache[cacheKey];
  }

  private static getCacheKey(from, to, date) {
    return `${from}_${to}_${date}`;
  }
}

export default CurrencyExchange;
