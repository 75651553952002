import { ClientOrderSource } from '@Ecommerce/ClientOrderSource';
import { FC } from 'react';

const SourceView: FC<{ source: null | ClientOrderSource }> = ({ source }) => {
  if (source === null) {
    return null;
  }

  return (
    <span
      style={{
        width: '100%',
        display: 'block',
        padding: '10px 8px',
        color: source?.color ? '#fff' : '#303030',
        backgroundColor: source?.color ?? '#dedede',
        borderRadius: 10,
        whiteSpace: 'nowrap',
      }}
    >
      {source?.name}
    </span>
  );
};

export default SourceView;
