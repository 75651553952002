import Swal, { SweetAlertOptions } from 'sweetalert2';

function useSwalLoader(title = 'Proszę czekać', description = 'Trwa wykonywanie operacji...') {
  return (action: Promise<any>) => {
    Swal.fire<any>({
      title: title,
      text: description,
      showConfirmButton: false,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      },
    } as SweetAlertOptions);

    action.finally(() => Swal.close());
  };
}

export default useSwalLoader;
