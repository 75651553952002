import DataGrid from '../DataGrid';
import { Col, Container } from '../Theme/Grid';
import ChangesTable from './ChangesTable';
import dayjs from 'dayjs';
import { useState } from 'react';
import { Button, Modal, ModalBody } from 'reactstrap';

const HistoryColumns = [
  {
    id: 'loggedAt',
    filterable: false,
    sortable: true,
    Header: 'Data zmiany',
    accessor: ({ loggedAt }) => dayjs(loggedAt).locale('pl').format('llll'),
  },
  {
    id: 'changes',
    filterable: false,
    sortable: false,
    Header: 'Zmiany',
    accessor: row => <ChangesTable row={row} />,
  },
];
const HistoryModuleButton = ({ id, module }) => {
  const [opened, setOpened] = useState(false);
  return (
    <>
      <Modal isOpen={opened} centered={true} backdrop={true} toggle={() => setOpened(!opened)} size="xl" style={{ maxWidth: '80vw' }}>
        <ModalBody>
          <Container>
            <Col xs={12}>
              <DataGrid
                url={`${module.api.item.get({ id })}?history=true`}
                fullWidth={true}
                responsive={true}
                columns={HistoryColumns}
                defaultPerPage={20}
                perPageOptions={[10, 20, 30, 40, 50]}
                defaultOrderBy={[{ id: 'loggedAt', desc: true }]}
                storeFilters={false}
              />
            </Col>
          </Container>
        </ModalBody>
      </Modal>

      <Button className="btn ml-1" color="primary" onClick={() => setOpened(true)}>
        <i className="mdi mdi-history" />
      </Button>
    </>
  );
};

export default HistoryModuleButton;
